import React, { useState, useRef, useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";
import banner2 from "../../../assets/landing/Banner2.png";
import banner1 from "../../../assets/landing/Banner1.jpg";
import banner3 from "../../../assets/landing/banner3.jpg";
import banner4 from "../../../assets/landing/banner4.jpg";

import bannerMob2 from "../../../assets/landing/BannerMob2.png";
import bannerMob1 from "../../../assets/landing/BannerMob1.jpg";
import bannerMob3 from "../../../assets/landing/banner3.jpg";
import bannerMob4 from "../../../assets/landing/BannerMob4.png";

import "./Landing.css";

const LandingPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [autoSlideInterval, setAutoSlideInterval] = useState(null);
  const videoRef = useRef(null);

  const slides = [
    // {
    //   type: "video",
    //   src: "https://firebasestorage.googleapis.com/v0/b/qmark-website-bk.appspot.com/o/WhatsApp%20Video%202024-10-04%20at%2013.54.21_2850322b.mp4?alt=media&token=4600c23e-50ae-41ad-a15d-ddf7fb4dc0c4",
    //   text: "",
    // },
    { type: "image", src: banner2, text: "" },
    { type: "image", src: banner1, text: "" },
    { type: "image", src: banner3, text: "" },

    { type: "image", src: banner4, text: "" },
  ];

  const mob = [
    // {
    //   type: "video",
    //   src: "https://firebasestorage.googleapis.com/v0/b/qmark-website-bk.appspot.com/o/WhatsApp%20Video%202024-10-04%20at%2013.54.21_2850322b.mp4?alt=media&token=4600c23e-50ae-41ad-a15d-ddf7fb4dc0c4",
    //   text: "",
    // },
    { type: "image", src: bannerMob2, text: "" },
    { type: "image", src: bannerMob1, text: "" },
    { type: "image", src: bannerMob3, text: "" },
    { type: "image", src: bannerMob4, text: "" },
  ];

  const { t } = useTranslation();
  const carouselRef = useRef(null);

  useEffect(() => {
    startAutoSlide();
    return () => {
      stopAutoSlide();
    };
  }, []);

  const startAutoSlide = () => {
    const interval = setInterval(() => {
      const isMobile = window.innerWidth < 768;
      const currentSlides = isMobile ? mob : slides;

      if (currentSlides[currentSlide].type === "video" && videoRef.current) {
        // Wait for the video to finish playing in both mobile and desktop views
        if (!videoRef.current.ended) {
          // Video hasn't finished, do not proceed to next slide
          return;
        }
      }
      nextSlide();
    }, 3000);
    setAutoSlideInterval(interval);
  };

  const stopAutoSlide = () => {
    clearInterval(autoSlideInterval);
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const handleVideoEnd = () => {
    nextSlide();
  };

  return (
    <div
      className="landing_page h-[430px] md:h-screen w-full relative overflow-hidden"
      ref={carouselRef}
    >
      <div className="w-full mx-auto absolute top-9 hidden md:block">
        {slides[currentSlide].type === "image" ? (
          <img
            src={slides[currentSlide].src}
            alt="bg"
            className="object-contain h-full w-full mt-24"
          />
        ) : (
          <video
            ref={videoRef}
            src={slides[currentSlide].src}
            className="object-contain h-full w-full "
            onEnded={handleVideoEnd} // Auto-slide after video ends
            controls={false}
            autoPlay
            muted
          />
        )}
      </div>
      <div className="w-full mx-auto absolute top-9 md:hidden">
        {mob[currentSlide].type === "image" ? (
          <img
            src={mob[currentSlide].src}
            alt="bg"
            className="object-contain h-full w-full mt-24"
          />
        ) : (
          <video
            ref={videoRef}
            src={mob[currentSlide].src}
            className="object-cover h-[300px] w-full mt-24"
            onEnded={handleVideoEnd}
            controls={false}
            autoPlay
            muted
          />
        )}
      </div>

      {/* Carousel */}
      <div className="relative h-full">
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="max-w-common w-full h-full mx-auto px-3 flex md:items-center">
            {/* Right Side image */}

            {/* Left Side Data */}
            <div className="mt-10 md:mt-20 flex flex-col items-start text-white max-md:items-center max-md:justify-center">
              <h1
                className="max-md:text-[38px] text-[60px] font-bold text-wrap w-1/2 max-md:w-10/12 flex 
                  text-start max-md:text-center max-md:leading-[45px] leading-[65px] md:tracking-wide"
              >
                {/* {t("landing.slogan")} */}
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="absolute inset-y-1/2 left-0 transform -translate-y-1/2 flex justify-between w-full px-5">
        <button
          onClick={() => {
            stopAutoSlide();
            prevSlide();
          }}
          className="text-white text-xl focus:outline-none hover:text-gray-300"
        >
          <IoIosArrowBack />
        </button>
        <button
          onClick={() => {
            stopAutoSlide();
            nextSlide();
          }}
          className="text-white text-xl focus:outline-none hover:text-gray-300"
        >
          <IoIosArrowForward />
        </button>
      </div>
    </div>
  );
};

export default LandingPage;
