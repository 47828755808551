import cardioIcon from "../../../assets/specialities/heart_organ 1.png";
import ico2 from "../../../assets/department/2.png";
import ico3 from "../../../assets/department/3.png";
import ico4 from "../../../assets/department/4.png";
import ico5 from "../../../assets/department/5.png";
import ico6 from "../../../assets/department/6.png";
import ico7 from "../../../assets/department/7.png";
import ico8 from "../../../assets/department/8.png";
import ico9 from "../../../assets/department/9.png";
import ico10 from "../../../assets/department/10.png";
import ico11 from "../../../assets/department/11.png";
import ico12 from "../../../assets/department/12.png";
import ico13 from "../../../assets/department/13.png";
import ico14 from "../../../assets/department/14.png";
import ico15 from "../../../assets/department/15.png";
import ico16 from "../../../assets/department/16.png";
import ico17 from "../../../assets/department/17.png";
import ico18 from "../../../assets/department/18.png";
import ico19 from "../../../assets/department/19.png";
import ico20 from "../../../assets/department/20.png";
import ico21 from "../../../assets/department/21.png";
import ico22 from "../../../assets/department/22.png";
import ico23 from "../../../assets/department/23.png";
import ico24 from "../../../assets/department/nephrology.png";
import ico25 from "../../../assets/department/laryngologyIcon.png";
import ico26 from "../../../assets/department/respiratorySystemIcon.png";


import De1 from "../../../assets/department/De1.jpg";
import De2 from "../../../assets/department/De2.jpg";
import De3 from "../../../assets/department/De3.jpg";
import De4 from "../../../assets/department/De4.jpg";
import De5 from "../../../assets/department/De5.jpg";
import De6 from "../../../assets/department/De6.jpg";
import De7 from "../../../assets/department/De7.webp";
import De8 from "../../../assets/department/De8.jpg";
import De9 from "../../../assets/department/De9.jpg";
import De10 from "../../../assets/department/De10.png";
import De11 from "../../../assets/department/De11.jpg";
import De12 from "../../../assets/department/De12.jpg";
import pediorth from "../../../assets/department/De2.jpeg";
import pedi from "../../../assets/department/pedi.webp";
import fam from "../../../assets/department/fam.jpg";
import anes from "../../../assets/department/anes.jpg";
import rad from "../../../assets/department/rad.jpg";
import icu from "../../../assets/department/icu.jpg";
import pedsurg from "../../../assets/department/pedsurg.jpg";
import robot from "../../../assets/department/robot.png";
import critic from "../../../assets/department/critic.jpg";
import physio from "../../../assets/department/physio.jpg";
import De13 from "../../../assets/department/De13.jpg";
import De13Sub from "../../../assets/department/De13Sub.jpg";
import De14 from "../../../assets/department/De14.jpg";
import De14Sub from "../../../assets/department/De14Sub.jpg";
import Nephro from "../../../assets/department/nephrologist.jpg"
import Laryngo from "../../../assets/department/Laryngology.jpg"
import Respiratory from "../../../assets/department/RespiratoryMedicine.jpg"
import ResSub from "../../../assets/department/RespiratoryMedicinesub.jpg"


import doc1 from "../../../assets/doctors/Dr PRAVEEN GL Consultant.png";
import doc2 from "../../../assets/doctors/Dr. Shifas Babu M senior consultant.png";
import doc3 from "../../../assets/doctors/Dr sunil kumar transparent image.png";
import doc4 from "../../../assets/doctors/Dr arun S, Consultant.png";
import doc5 from "../../../assets/doctors/Anwar Rasheed, Sen. Consultant.png";
import doc6 from "../../../assets/doctors/Dr Somaraj Senior consultant.png";
import doc7 from "../../../assets/doctors/Dr Philip oommen, senior consultant.png";
import doc8 from "../../../assets/doctors/Dr sonu kurian, Consultant.png";
import doc9 from "../../../assets/doctors/Dr. Boben Thomas Sen. Consultant.png";
import doc10 from "../../../assets/doctors/Dr Arun Viswanathan Consultant.png";
import doc11 from "../../../assets/doctors/Dr justin Thomas Jun. Consultant.png";
import doc12 from "../../../assets/doctors/Dr niranjan Sen. Consultant.png";
import doc13 from "../../../assets/doctors/Dr SHIBU JHON Sen. Consultant.png";
import doc14 from "../../../assets/doctors/Dr. praveen Consultant.png";
import doc15 from "../../../assets/doctors/Dr VIJAYA KUMAR Sen. Consultant.png";
import doc16 from "../../../assets/doctors/Dr. Ashok Thomas, Group director spine and ortho.png";
import doc17 from "../../../assets/doctors/Dr. Sasi Kumar Sen. Consultant.png";
import doc18 from "../../../assets/doctors/Dr. Vidya Vimal.png";
import doc19 from "../../../assets/doctors/Dr Dhanya Louis JS.png";
import doc20 from "../../../assets/doctors/Dr. Naveen K.png";
import doc21 from "../../../assets/doctors/Dr. Riju Sathar.png";
import doc22 from "../../../assets/doctors/Dr. Anu Antony Varghese.png";
import doc24 from "../../../assets/doctors/Dr. Aswathy D Pillai.png";
import doc25 from "../../../assets/doctors/Dr. Abdul Samad K.png";
import doc26 from "../../../assets/doctors/Dr. Vivek Parameswara Sarma.png";
import doc27 from "../../../assets/doctors/Dr. Ajai Sasidhar.png";
import doc28 from "../../../assets/doctors/Dr. Sreejith M Cheruvilakam.png";
import doc31 from "../../../assets/doctors/Dr. Nanda Gopan R.png";
import manorama from "../../../assets/doctors/manorama.png";
import Jayakumar from "../../../assets/doctors/Jayakumar.png";
import SreejithMD from "../../../assets/doctors/Sreejith MD.png";
import sreelal from "../../../assets/doctors/dr sreelal.png";
import nidhin from "../../../assets/doctors/Dr Nidhin ENT.png";
import Dr_Arun_Baby from "../../../assets/doctors/Dr_Arun_Baby.png";
import Dr_Karthik_Vijayakumar from "../../../assets/doctors/Dr Karthik Vijayakumar.png";
import Dr_Aisha_Beevi_P_K from "../../../assets/doctors/Dr Aisha Beevi P K.png";
import Dr_Vidhya_K_Zachariah from "../../../assets/doctors/Dr Vidhya K Zachariah.png";
import Dr_Manju_E_Issac from "../../../assets/doctors/Dr Manju E Issac.png";
import Dr_Vipin_P_V from "../../../assets/doctors/Dr Vipin P V.png";
import Dr_Sujeesh_R from "../../../assets/doctors/Dr Sujeesh R.png";
import Dr_Kishore_M_A from "../../../assets/doctors/Dr Kishore M A.png";
import Dr_Arun_Kumar_R from "../../../assets/doctors/Dr_Arun_Kumar_R.png";
import Dr_Mudhasar from "../../../assets/doctors/Dr_Mudhasar.png";
import Dr_Chandramohan_K from "../../../assets/doctors/Dr. Chandramohan K.png";
import Dr_Teena_Nelson from "../../../assets/doctors/dr teena.png";

import orthoz from "../../../assets/department/orthoz.jpg";
import pediorthoz from "../../../assets/department/pediorthoz.jpg";
import spinez from "../../../assets/department/spinez.jpg";
import plasticz from "../../../assets/department/plasticz.jpg";
import internalz from "../../../assets/department/internalz.jpg";
import cardioz from "../../../assets/department/cardioz.jpg";
import dermaz from "../../../assets/department/dermaz.jpg";
import pediz from "../../../assets/department/pediz.jpg";
import famz from "../../../assets/department/famz.jpg";
import entz from "../../../assets/department/entz.jpg";

import anaesz from "../../../assets/department/anaesz.jpg";
import criticz from "../../../assets/department/criticz.jpg";
import emergz from "../../../assets/department/emergz.jpg";
import gensurgz from "../../../assets/department/gensurgz.jpg";
import icuz from "../../../assets/department/icuz.jpg";
import neuroz from "../../../assets/department/neuroz.jpg";
import oncz from "../../../assets/department/oncz.jpg";
import pedsurgz from "../../../assets/department/pedsurgz.jpg";
import physioz from "../../../assets/department/physioz.jpg";
import roboz from "../../../assets/department/roboz.jpg";
import uroz from "../../../assets/department/uroz.jpg";

export const doctors = [
  // Dr. Praveen GL
  // Cardiology
  {
    id: 1,
    Image: doc1,
    department: "Cardiology",
    name: "Dr. Praveen GL",
    qualification: "MBBS, MD, DM, DrNB (Cardiology)",
    title: "Consultant",
    experiences: [
      "10+ Years of Clinical Practice",
      "> 1 lac Treated Patients",
      "National Award Winner for Best Clinical Research in Cardiology",
      "University Gold Medalist",
      "National & International Awards for Best Cases",
    ],

    areas_of_expertise: [
      "Interventional Cardiology (Angioplasty, Pacemaker, ICD, CRT)",
      "Heart Attack & Heart Failure Management",
      "Valvular Heart Disease Management",
      "Structural & Peripheral Arterial Disease Interventions",
      "Preventive Cardiology",
    ],

    opd_timings: [],
  },
  // Dr. Shifas Babu M
  // Cardiology
  {
    id: 2,
    Image: doc2,
    department: "Cardiology",
    name: "Dr. Shifas Babu M",
    qualification: "MD, DM, DrNB, MNAMS, FACC",
    title: "Senior Consultant",
    experiences: [
      "10+ years of Clinical Practice",
      ">100,000 patients treated",
    ],
    areas_of_expertise: [
      "Interventional Cardiology (Angioplasty, Pacemaker, ICD)",
      "Heart Attack & Heart Failure management",
      "Structural & Peripheral Arterial Disease Intervention",
      "Preventive Cardiology",
    ],

    opd_timings: [{ days: "Mon, Wed, Fri", time: "9 am - 4 pm" }],
  },
  // Dr. Sunil Kumar S
  // Dermatology
  {
    id: 3,
    Image: doc3,
    department: "Dermatology",
    name: "Dr. Sunil Kumar S",
    qualification: "MBBS, MD",
    title: "Consultant",
    experiences: [
      "25 years of Clinical Practice",
      ">9 lacs patients treated",
      "Inspiring Dermatologist Award - TOI 2001",
    ],
    areas_of_expertise: [
      "All clinical dermatology conditions (Eczema, Psoriasis)",
      "Platelet Rich Plasma Transfer for Hair Growth",
      "Diode Laser for Hair Removal",
      "Chemical Peels for Pigmentation",
      "Hydra-facial for skin rejuvenation",
    ],

    opd_timings: [{ days: "Mon-Sat", time: "9 am-11 pm" }],
  },
  // Dr. Somarajan M R
  // General Surgery
  {
    id: 5,
    Image: doc6,
    department: "General Surgery",
    name: "Dr. Somarajan M R",
    qualification: "MBBS, MS (Gen. Surgery), FMAS",
    title: "Senior Consultant",
    experiences: [
      "34+ years of Surgical Practice",
      "> 25000 patients operated upon",
      "> 10000 laparoscopy surgeries",
    ],
    areas_of_expertise: [
      "Advanced Minimally Invasive Surgeries",
      "Flexible Endoscopic Interventional Gastrointestinal procedures",
      "Bariatric Surgery (Gastric Bypass, Sleeve)",
      "Breast Augmentation & Reconstruction",
      "Minimally Invasive GI Cancer surgeries",
      "Thoracoscopic surgeries",
      "Laser Proctology & Varicose Vein procedures",
    ],

    opd_timings: [{ days: "Mon - Sat", time: "9 am-2 pm" }],
  },
  // Dr. Arun S
  // Emergency Medicine
  {
    id: 6,
    Image: doc4,
    department: "Emergency Medicine",
    name: "Dr. Arun S",
    qualification: "MBBS, MEM, MBA",
    title: "Consultant ",
    experiences: [
      "7+ years of Emergency Care Experience",
      "Program Director of Masters of Emergency Medicie (MEM -  CCT-EM ) Under SEMI",
      "Instructor of Additional Skill Acquisition Programme (ASAP) under Education Department, Govt.of Kerala",
      `Team member of BLS and Trauma care training programme for Department of Kerala Police`,
    ],
    areas_of_expertise: [
      "Emergency and Critical Care medicine",
      "Management of acute traumatic injuries",
      "Pediatric emergencies",
      "Emergency Ultrasound, Toxicology",
      "BLS, ACLS, PALS, NALS, ATLS, ITLS, HAZMAT",
      "Trauma care by International Federation for Emergency Medicine (IFEM)",
    ],
    opd_timings: [],
  },
  // Internal Medicine
  // Dr. Philip Oommen
  {
    id: 7,
    Image: doc7,
    department: "Internal Medicine",
    name: "Dr. Philip Oommen",
    qualification: "MBBS, MD [Gen. Medicine]",
    title: "Sr. Consultant",
    experiences: [
      "30+ years of clinical practice",
      "6+ years as Asst Prof at KMC manipal ",
      "> 450,000 patients treated",
    ],
    areas_of_expertise: [
      "Diagnostics",
      "Immunology",
      "Infectious Diseases",
      "Haematology",
    ],
    opd_timings: [{ days: "Mon - Sat", time: "9 am - 5 pm" }],
  },
  // Dr. Sonu kurian
  // Neurology
  {
    id: 8,
    Image: doc8,
    department: "Neurosciences",
    name: "Dr. Sonu kurian",
    qualification: "MBBS, MD (Gen. Medicine), DM Neurology",
    title: "Consultant",
    experiences: [
      "4+ years of Clinical Practice in Neurology",
      "Trained in Interventional Pain Management",
    ],
    areas_of_expertise: [
      "Autoimmune Neurology",
      "Brain & spinal cord injury",
      "Dementia",
      "Epilepsy",
      "Headache disorders",
      "Movement Disorders",
      "Neuro-ophthalmology",
      "Neuromuscular disorders",
      "Pain management",
      "Sleep disorders",
      "Stroke",
      "Vertigo",
    ],
    opd_timings: [{ days: "Mon - Sat", time: "9 am-3 pm" }],
  },
  // Dr. Boben Thomas
  // Oncology
  {
    id: 9,
    Image: doc9,
    department: "Oncology",
    name: "Dr. Boben Thomas",
    qualification: "MBBS, MD, DNB, FPO, ECMO",
    title: "Senior Consultant",
    experiences: [
      "15+ years of Medical Oncology Practice",
      "Fellowship in Pediatric Oncology, Tata Memorial Hospital, Mumbai",
      "ECMO, European Society of Medical Oncology",
    ],
    areas_of_expertise: [
      "Adult Solid & Hematological malignancy",
      "Targeted therapy",
      "Immunotherapy",
      "Precision Oncology",
    ],

    opd_timings: [
      { days: "Saturday", time: "9 am - 5 pm" },
      { days: "Sunday", time: "9 am - 2 pm" },
    ],
  },
  // Dr. Shibu Jhon
  // Orthopedics
  {
    id: 13,
    Image: doc13,
    department: "Orthopedics",
    name: "Dr. Shibu John",
    qualification: "MBBS, MS (Ortho)",
    title: "Senior Consultant",
    experiences: ["23+ years of Orthopedic Practice"],
    areas_of_expertise: [
      "Total Knee Arthroplasty",
      "Total Hip Arthroplasty",
      "Trauma Surgery",
      "Knee Arthroscopy",
    ],
    opd_timings: [],
  },
  // Dr. Praveen S Jose
  // Pediatric Orthopedics
  {
    id: 14,
    Image: doc14,
    department: "Pediatric Orthopedics",
    name: "Dr. Praveen S Jose",
    qualification: "MBBS , MS (Ortho)",
    title: "Senior Consultant",
    experiences: ["15+ years of surgical experience"],
    areas_of_expertise: [
      "Management of orthopedic congenital disorders",
      "Common orthopedic conditions in children",
      "Pediatric bone and joint infections",
      "Fractures in children",
      "Deformity correction",
      "Limb lengthening",
      "Hip disorders",
      "Cerebral palsy",
    ],
    opd_timings: [{ days: "Mon, Tue, Thu, Sat", time: "9 am - 3pm" }],
  },
  // Dr. Ashok Thomas
  // Spine Surgery
  {
    id: 16,
    Image: doc16,
    department: "Spine Surgery",
    name: "Dr. Ashok Thomas",
    qualification:
      "MBBS (JIPMER), MS(Ortho) (PGI), DNB, MCh (Orth), FNB (Spine), FSS USA()",
    title: "Senior Consultant",
    experiences: [
      "17+ years of surgical experience",
      "Established Kerala's 1st exclusive Spine surgery unit",
      "1st in South Kerala with Superspecialty degree of FNB (Spine Surgery)",
      "Clinical Research Award 2012, by Association of Spine Surgeons of India ",
      "Trained at Spine Centers in USA, Sweden",
    ],
    areas_of_expertise: [
      "Minimally Invasive (Keyhole) spine surgeries",
      "Advanced Spine Microscopic surgeries",
      "Disc prolapsem, Spine fractures",
      "Scoliosis & Kyphosis correction Surgeries",
      "Complex reconstruction of spine",
    ],
    opd_timings: [],
  },
  // Dr. Sasi Kumar
  // Urology
  {
    id: 17,
    Image: doc17,
    department: "Urology",
    name: "Dr. Sasi Kumar N P ",
    qualification: "MBBS, MS, MCh (Urology)",
    title: "Senior Consultant",
    experiences: [
      "40+ years of vast experience in Urology",
      "Chief Transplant surgeon Sree Mookambika Medical College",
    ],
    areas_of_expertise: [
      "TURP Transurethral resection of the prostate",
      "URS - Ureteroscopy for urinary problems",
      "RIRS Retrograde intrarenal surgery",
      "PCN - Percutaneous nephrostomy",
      "Holmium laser enucleation of the prostate (HOLEP)",
      "Laser Lithotripsy for kidney stones",
      "Open & laparoscopic urology surgeries",
      "Robotic surgery for prostate & bladder cancers",
    ],
    opd_timings: [],
  },
  // Dr. Vidya Vimal
  // Pediatrics
  {
    id: 18,
    Image: doc18,
    department: "Pediatrics",
    name: "Dr. Vidya Vimal",
    qualification: "MBBS, DCH, DNB, MNAMS, PGPN (Nutrition)",
    title: "Sr. Consultant",
    experiences: [
      "15+ years of Clinical Practice",
      "> 300,000 children treated",
    ],
    areas_of_expertise: [
      "Pediatric Nutrition",
      "Pediatric Asthma & Allergies",
      "New-born Care",
      "Well-Baby Checkups",
      "Pediatric Vaccinations",
      "Growth & Development",
      "Childhood Illness Management",
      "Adolescent Counselling",
    ],

    opd_timings: [{ days: "Mon - Sat", time: "10.30 am - 3pm" }],
  },
  // Dr Dhanya Louis JS
  // Family Medicine
  {
    id: 19,
    Image: doc19,
    department: "Family Medicine",
    name: "Dr Dhanya Louis JS",
    qualification: "MBBS, DNB [Family Medicine]",
    title: "Physician",
    experiences: [
      "10 years of Family Medicine Practice",
      ">140,000 diverse patients of all ages treated",
    ],
    areas_of_expertise: [
      "Primary Care",
      "Preventive Health",
      "Adolescent Health",
      "Family Counselling",
      "Women Health",
    ],

    opd_timings: [{ days: "Mon - Fri", time: "9 am - 5 pm" }],
  },
  // Urology
  // Dr. Naveen K
  {
    id: 20,
    Image: doc20,
    department: "Urology",
    name: "Dr. Naveen K",
    qualification: "MBBS, MS (Surgery), MCh (Urology)",
    title: "Consultant",
    experiences: [
      "5+ years of experience",
      "5000+ Urology & Andrology procedures",
      "100+ Renal Transplant Surgeries",
    ],
    areas_of_expertise: [
      "Stone Clinic (Kidney / Ureter / Bladder)",
      "Prostate Clinic",
      "Laser Lithotripsy (URSL/RIRS/PCNL/PCCL)",
      "Laparoscopic Urology/Urogynecology Procedures",
      "Laparoscopic / open prostatectomy / cystectomy / neo-bladder/ Radical / partial nephrectomy",
      "Genito Urinary Oncology",
      "Andrology (Erectile Dysfunction / Male Infertility)",
      "Renal Transplantation",
    ],
    opd_timings: [],
  },
  // Dr. Riju Sathar
  // Oral
  {
    id: 21,
    Image: doc21,
    department: "Oral & Maxillofacial Surgery",
    name: "Dr. Riju Sathar",
    qualification:
      "MDS, MDS, MCHT, Fellowship in Head & Neck Surgical Oncology",
    title: "Consultant",
    experiences: ["4+ years of surgical experience with top-notch hospitals"],
    areas_of_expertise: [
      "Oral Cancer Management",
      "Maxillofacial Trauma",
      "Dental Implantology & pre-prosthetic surgeries",
      "Hair Transplants (MCHT)",
      "Benign pathology of head & neck region",
      "Third molar tooth surgeries",
      "TMJ (temporomandibular joint) Disorders",
    ],
    opd_timings: [],
  },
  // Dr. Anu Antony Varghese
  // Robotic & Laparoscopic General Surgery
  {
    id: 22,
    Image: doc22,
    department: "Robotic & Laparoscopic General Surgery",
    name: "Dr. Anu Antony Varghese",
    qualification: "MS, FIAGES, FALS",
    title: "Senior Consultant",
    experiences: [
      "19+ years of surgical experience",
      "7 Fellowships in Laparoscopic surgery",
      "3 Bariatric surgery Fellowships",
      "Robotic surgery training from Cambridge Medical Research UK",
    ],
    areas_of_expertise: [
      "Robotic surgeries (e.g., GI, hysterectomy & thyroid)",
      "Advanced General Surgeries",
      "Pinhole surgery for Varicose veins",
      "Minimally invasive Ano-rectal surgery",
      "Bariatric surgery (Sleeve gastrectomy)",
      "Endoscopic Thyroid & Parathyroid surgery",
      "Minimally Invasive Hernia surgeries",
    ],
    opd_timings: [],
  },

  // Dr. Aswathy D Pillai
  // Pediatrics
  {
    id: 24,
    Image: doc24,
    department: "Pediatrics",
    name: "Dr. Aswathy D Pillai",
    qualification: "MBBS, DCH, MRCPCH, PGDAP. PGPN",
    title: "Senior Consultant",
    experiences: [
      "20+ years of clinical practice",
      ">5 lakhs patients treated",
    ],
    areas_of_expertise: [
      "Childhood Illness Management",
      "Pediatric Emergencies",
      "Adolescent Medicine",
      "Pediatric Allergic & Asthma",
      "Neonatal Care",
      "Well-Baby Clinic & Health Checkups",
      "Growth, Development & Immunization Clinic",
    ],
    opd_timings: [],
  },

  // Dr. Abdul Samad K
  // Dermatology
  {
    id: 25,
    Image: doc25,
    department: "Dermatology",
    name: "Dr. Abdul Samad K",
    qualification: "MBBS, MD (Dermatology & Venereology)",
    title: "Senior Consultant",
    experiences: [
      "29+ years of clinical practice",
      ">7 lakhs patients treated",
    ],
    areas_of_expertise: [
      "All clinical dermatology conditions",
      "Bilogics (MAB) for all skin conditions",
      "Skin grafting for vitiligo",
      "Glutathione injections for skin brightening",
      "Electro-surgery for benign skin brightening",
      "Intra-lesional injection therapies",
      "Platelet Rich Plasma Transfer for Hair Growth",
      "Diode Laser for Hair Removal",
      "Chemical Peels for Pigmentation",
      "Hydra-facial for skin rejuvenation",
    ],
    opd_timings: [],
  },

  // Dr. Vivek Parameswara Sarma
  // Pediatric Surgery
  {
    id: 26,
    Image: doc26,
    department: "Pediatric Surgery",
    name: "Dr. Vivek Parameswara Sarma",
    qualification: "MBBS, MS, DNB (Surgery), MCh, DNB (Paediatric Surgery)",
    title: "Senior Consultant",
    experiences: [
      "17+ years of Paediatric Surgery Practice",
      ">10,000 Paediatric surgeries performed",
      "24 research papers published in international peer-reviewed journals",
      "1st prize for Best Paper presentation at IAPS Conference for 3 consecutive years",
    ],
    areas_of_expertise: [
      "Paediatric Genitourinary surgery",
      "Paediatric Urology",
      "Surgery for Hypospadias & DSD",
      "Neonatal Surgery",
      "Endoscopic Surgery",
      "Minimal Access / Laparoscopic surgery",
      "Reconstructive surgeries (cleft lip & plate)",
      "Daycare procedures (Hernia, Circumcision, Undescended Testes)",
    ],
    opd_timings: [],
  },
  // Dr. Ajai Sasidhar
  // oncology
  {
    id: 27,
    Image: doc27,
    department: "Oncology",
    name: "Dr. Ajai Sasidhar",
    qualification: "MBBS, MS (Gen. Surgery), MCh (Surgical Oncology) - AIIMS",
    title: "Consultant ",
    experiences: ["9+ years of Surgical Experience"],
    areas_of_expertise: [
      "Breast cancer - breast conservation surgeries, Oncoplastic surgeries",
      "Oral cavirt, Thyroid, Head & Neck Cancer surgeries",
      "Open & Minimally Invasive surgeries",
      "Skin cancer surgeries",
      "Musculoskeletal tumours & scarcomas",
      "Reconstrictive surgeries after major resections",
    ],
    opd_timings: [],
  },

  // Dr. Sreejith M Cheruvilakam
  // Psychiatry & Behavioural Medicine
  {
    id: 28,
    Image: doc28,
    department: "Psychiatry & Behavioural Medicine",
    name: "Dr. Sreejith M Cheruvilakam",
    qualification: "MBBS, DCH, MRCPCH, MD (Psychiatry)",
    title: "Consultant ",
    experiences: [
      "19+ years of clinical experience",
      "10+ years in the United Kingdom",
    ],
    areas_of_expertise: [
      "Psychiatric counselling & therapy",
      "Anxiety disorder management (PTSD)",
      "Stress, Depression & psychotic disorders",
      "Personality modulation expert",
      "Interpersonal relationships",
      "ADHD & Behavioral issues in children",
      "Marriage counselling",
      "Meditation & yoga",
      "Anger managemen",
      "Dementia",
    ],
    opd_timings: [],
  },

  // Dr. Nanda Gopan R
  // Physiotherapist
  {
    id: 31,
    Image: doc31,
    department: "Physiotherapy",
    name: "Dr. Nanda Gopan R",
    qualification: `MPT, COMT, KCMT`,
    title: "Consultant ",
    experiences: [
      "14+ years of Physiotherapy practice",
      "Kinetic control movement Therapist",
      "Gold medallist, PSG College of Physiotherapy, PSGIMSR Coimbatore",
    ],
    areas_of_expertise: [
      "Neck and back pain",
      "Fibromyalgia",
      "Shoulder rehabilitation ",
      " Hand therapy",
      " TMJ dysfunction",
    ],
    opd_timings: [],
  },

  // Dr. Anwar Rasheed
  // ENT
  {
    id: 4,
    Image: doc5,
    department: "ENT",
    name: "Dr. Anwar Rasheed",
    qualification: `MBBS, MS (ENT)`,
    title: "SENIOR CONSULTANT ",
    experiences: [
      "18+ years of ENT Practice",
      "Professor ENT - Travancore Medical College",
    ],
    areas_of_expertise: [
      "Otology (ear diseases & hearing loss)",
      "Cochlear Implants",
      "All ear surgeries",
      "Pediatric Otolaryngology",
      "Rhinology",
      "Laryngology",
      "Allergies (rhinitis, chronic sinusitis, laryngitis, sore throat, otitis media)",
    ],
    opd_timings: [],
  },

  // Dr. Niranjan
  // Orthopedics
  {
    id: 12,
    Image: doc12,
    department: "Orthopedics",
    name: "Dr. Niranjan ",
    qualification: `MBBS, D (Ortho), DNB, Fellowship in Arthroplasty )`,
    title: "SENIOR CONSULTANT",
    experiences: [
      "15+ years of Clinical Practice",
      "> 1 lac patients treated",
      "International training on Joint Replacement",
      "Fellowship in Joint Replacement (S. Korea & Italy)",
      "AO Masters Upper Limb Trauma & Pelvic Trauma",
      "Faculty for AO Trauma",
    ],
    areas_of_expertise: [
      "Joint replacement surgery",
      "Revision Joint replacement",
      "Pelviacetabular surgeries",
      "Complex Orthopaedic Trauma management",
      "Joint preservation procedures",
      "Arthroscopy",
    ],
    opd_timings: [],
  },

  // Dr. Sreejith MD
  // Neurosurgery
  {
    id: 29,
    Image: SreejithMD,
    department: "Neurosurgery",
    name: "Dr. Sreejith MD",
    qualification: `MBBS, MS (Gen. surgery), MCh (Neurosurgery), Fellowship (Paediatric Neurosurgery), Fellowship (Skull base Endoscopic Neurosurgery)`,
    title: "Consultant",
    experiences: ["7+ years of surgical experience"],
    areas_of_expertise: [
      "Paediatric trauma",
      "Paediatric neural tube defect correction surgeries",
      "Paediatric Cranio-synostosis correction surgeries",
      "Paediatric Brain Tumours",
      "Adult Head Injuries",
      "Adult Spinal Cord Tumours",
    ],
    opd_timings: [],
  },

  // Dr. Sreelal S
  // Plastic Surgery
  {
    id: 30,
    Image: sreelal,
    department: "Plastic Surgery",
    name: "Dr. Sreelal S",
    qualification: `MBBS, MS (Surgery), MCh (Plastic Surgery)`,
    title: "Senior Consultant ",
    experiences: ["14+ years of surgical experience"],
    areas_of_expertise: [
      "Microsurgical reconstruction",
      "Reimplants",
      "Hand surgery",
      "Breast reconstruction",
      "Brachial plexus",
      "Congenital anomaly correction",
      "Cosmetic surgery (gynaecomastia, rhinoplasty, liposuction)",
      "Hair Transplants (FUE, FUT, PRP)",
    ],
    opd_timings: [],
  },

  // Dr. Arun Baby
  // Rheumatology
  {
    id: 23,
    Image: Dr_Arun_Baby,
    department: "Rheumatology",
    name: "Dr. Arun Baby",
    qualification: "MBBS, MD, DM (Clinical Immunology & Rheumatology)",
    title: "Senior Consultant",
    experiences: ["19+ years of clinical experience"],
    areas_of_expertise: [
      "Rheumatoid Arthritis",
      "Systemic Lupus Erythematosus (SLE)",
      "Mixed Connective tissue Disorder",
      "Psoriatic arthritis / Ankylosing Spondylitis",
      "Sjogrens Sclerosis",
      "Antiphospholipid Syndrome",
      "Juvenile Idiopathic Arthritis",
      "Myositis",
      "Gout",
      "Vasculitis",
      "Osteoarthritis / Osteoporosis",
      "Fibromyalgia",
      "Soft tissue Rheumatism",
    ],
    opd_timings: [],
  },

  // Dr. Manorama Devi K Rajan
  // Neurosciences
  {
    id: 51,
    Image: manorama,
    department: "Neurosciences",
    name: "Dr. Manorama Devi K Rajan",
    qualification: `MBBS, MD (Medicine), DM (Neuro), DNB (Neuro), FAAN (American Academy of Neurology)`,
    title: "Senior Consultant",
    experiences: ["46+ years of clinical practice"],
    areas_of_expertise: [
      "General neurology",
      "Epilepsy",
      "Stroke",
      "Dementia",
      "Headaches",
      "Autoimmune Neurology",
      "Multiple sclerosis",
      "Peripheral nervous system diseases",
      "Myasthenia Gravis & Muscle disorders",
    ],
    opd_timings: [],
  },

  // Dr. Nidhin SB
  // ENT
  {
    id: 52,
    Image: nidhin,
    department: "ENT",
    name: "Dr. Nidhin SB",
    qualification: `MBBS, MS ENT, DNB ENT, MNAMS, AASC, Fellowship in Laryngology (RCS)`,
    title: "CONSULTANT - ENT HEAD & NECK SURGEON",
    experiences: [
      "4+ years of experience",
      "Ex Senior Resident AIIMS Raipur",
      "Ex Assistant Professor Govt. Medical College Raipur",
      "Trained in Laser & Trans Oral Robotic Surgery (DMH, Pune)",
      "20+ publications in International and National Journals",
    ],
    areas_of_expertise: [
      "Snoring surgery, sleep study, sleep endoscopy",
      "Allergy (skin prick testing, immunotherapy)",
      "Vertigo (VNG, Caloric testing, vestibular rehab)",
      "Minimal invasive micro ear surgery",
      "Endoscopic Sinus Surgery & Septoplasty",
      "Endoscopic Adenoidectomy, Tonsillectomy",
      "Ca Larynx - Trans Oral Laser Cordectomy",
      "Vocal Cord Palsy - Thyroplasty, Injection laryngoplasty",
      "Thyroidectomy, Parotidectomy",
    ],
    opd_timings: [],
  },

  // Dr. Jayakumar
  // Orthopedics
  {
    id: 53,
    Image: Jayakumar,
    department: "Laryngology",
    name: "Dr. Jayakumar R Menon",
    qualification: `MBBS, MS, DLO, DNB, FRCS`,
    title: "HOD & SENIOR CONSULTANT -LARYNGOLOGY",
    experiences: [
      `A true pioneer, his remarkable journey of over 38 years in laryngology has earned him the well- deserved 
            reputation as a leading expert in voice, airway & swallowing disorders, thyroid surgery, professional 
            voice care and neuro-laryngology.`,
    ],
    areas_of_expertise: [],
    opd_timings: [],
  },

  // DR KARTHIK VIJAYAKUMAR
  // Endocrinology
  {
    id: 54,
    Image: Dr_Karthik_Vijayakumar,
    department: "Endocrinology",
    name: "Dr Karthik Vijayakumar",
    qualification: `MD (Medicine), DM, DrNB (Endocrinology), EBEEDM (RCP Ireland)`,
    title: "Consultant",
    experiences: [],
    areas_of_expertise: [
      "Diabetes mellitus",
      "Obesity",
      "Thyroid disorders",
      "Pituitary and Adrenal disorders",
      "Osteoporosis",
      "Short stature and pubertal disorders in children ",
    ],
    opd_timings: [],
  },

  // Dr Aisha Beevi P K
  // OPHTHALMOLOGY
  {
    id: 55,
    Image: Dr_Aisha_Beevi_P_K,
    department: "Ophthalmology",
    name: "Dr Aisha Beevi P K ",
    qualification: `MBBS, DO (Ophthalmology) `,
    title: "Senior Consultant",
    experiences: [`>14 years as ophthalmologist`],
    areas_of_expertise: [
      "Diabetic Retinopathy screening ",
      "Glaucoma evaluation ",
      "Retinal evaluation ",
      "Cataract surgery ",
      "OP procedures & treatments",
    ],
    opd_timings: [],
  },

  // Dr Vidhya K Zachariah
  // Nephrology
  {
    id: 56,
    Image: Dr_Vidhya_K_Zachariah,
    department: "Nephrology",
    name: "Dr Vidhya K Zachariah",
    qualification: `MBBS, MD (Gen. Medicine), DNB (Gen. Medicine), DM (Nephrology) - JIPMER`,
    title: "Consultant",
    experiences: [
      `4+ years of clinical practice`,
      `Young Investigator Award – Indian Society of Nephrology SC`,
    ],
    areas_of_expertise: [
      "General Nephrology",
      "Acute Kidney Injury ",
      "Chronic Kidney disease",
      "Glomerular diseases",
      "Haemodialysis ",
      "Peritoneal Dialysis ",
      "Renal Transplantation ",
      "Kidney Biopsy",
    ],
    opd_timings: [],
  },

  // Dr_Manju_E_Issac
  // Laryngology
  {
    id: 57,
    Image: Dr_Manju_E_Issac,
    department: "Laryngology",
    name: "Dr Manju E Issac",
    qualification: `DLO, DNB (ENT), MNAMS, Fellowship In Laryngology & Phonosurgery`,
    title: "Senior Consultant",
    experiences: [`10 + years experience in Laryngology & Sialendoscopy`],
    areas_of_expertise: [
      "Phonosurgeries / surgeries to improve voice",
      "Thyroplasty, Injection Laryngoplasty for unilateral vocal folds palsy",
      "Voice Therapy ",
      "Professional Voice Care",
      "Dysphagia Surgeries (swallowing problems) ",
      "Airway Surgeries (Surgery for upper airway obstruction, Bilateral Vocal Cord Palsy)",
      "Botox for spasmodic dysphonia and cricopharyngeal dysmotility",
      "Thyroid surgery",
      "Reinnervation surgeries for Voice and Airway",
      "Surgery for Salivary gland Tumours & Drooling",
      "Sialendoscopy (Salivary gland endoscopy)",
    ],
    opd_timings: [],
  },

  // Dr_Vipin_P_V
  // Respiratory Medicine
  {
    id: 58,
    Image: Dr_Vipin_P_V,
    department: "Respiratory Medicine",
    name: "Dr Vipin P V",
    qualification: `MBBS, MD (Respiratory Medicine), DTCD, EDARM`,
    title: "Senior Consultant",
    experiences: [
      `22+ years of experience`,
      `Former Professor of Respiratory Medicine – Sree Mookambika Institute of Medical Sciences, Kulasekharam`,
      `> 200,000 patients treated`,
    ],
    areas_of_expertise: [
      "Asthma",
      "Respiratory infections including H1N1, COVID",
      "Tuberculosis",
      "Interstitial lung diseases",
      "Intra-dermal allergy testing",
      "Sleep apnea",
      "COPD",
    ],
    opd_timings: [],
  },

  // Dr_Sujeesh_R
  // Critical Care Medicine
  {
    id: 59,
    Image: Dr_Sujeesh_R,
    department: "Critical Care",
    name: "Dr Sujeesh R",
    qualification: `MBBS, Dip. (Anaesthesiology), MD (Anaesthesiology & Critical Care – Gold Medal), DM (Critical Care – JIPMER)`,
    title: "Hod & Senior Consultant ",
    experiences: [`15+ years experience in critical care`],
    areas_of_expertise: [
      "Trauma Care",
      "Extra Corporeal Life Support",
      "Nephro Critical Care",
      "Neuro Critical Care",
      "Humanizing ICU",
      "Mechanical Ventilation",
      "Critical care nutrition ",
      "Infectious Diseases",
    ],
    opd_timings: [],
  },

  // Dr_Kishore_M_A
  // Critical Care Medicine
  {
    id: 60,
    Image: Dr_Kishore_M_A,
    department: "Oral & Maxillofacial Surgery",
    name: "Dr Kishore M A",
    qualification: `Bds, Mds (Omfs), Pgdhm`,
    title: "Senior Consultant ",
    experiences: [`23+ years`, `> 5,000 Surgeries`],
    areas_of_expertise: [
      "Oro-Facial Cancer Surgeries",
      "Oro-Facial Plastic & Reconstructive Surgeries",
      "Facial Cosmetic Surgeries ",
      "Management of Oral & Maxillofacial Injuries",
      "Management of Temporomandibular Joint Disorders",
      "Management of Dento-Alveolar Problems Including Problematic Tooth Removal",
      "Management of Oro- Facial Infections and other Pathologies",
    ],
    opd_timings: [],
  },

  // Dr Arun Kumar R
  // Orthopedics
  {
    id: 61,
    Image: Dr_Arun_Kumar_R,
    department: "Orthopedics",
    name: "Dr Arun Kumar R",
    qualification: `MBBS, XX`,
    title: "Consultant ",
    experiences: [`15+ years of `],
    areas_of_expertise: ["Adult"],
    opd_timings: [],
  },

  // Dr_Mudhasar
  // Orthopedics
  {
    id: 62,
    Image: Dr_Mudhasar,
    department: "Orthopedics",
    name: "Dr Mudhasar",
    qualification: `MBBS, MD, DNB`,
    title: "Consultant ",
    experiences: [`15+ years of `],
    areas_of_expertise: ["Adult"],
    opd_timings: [],
  },

  // Dr. Justin Thomas
  // Orthopedics
  {
    id: 11,
    Image: doc11,
    department: "Orthopedics",
    name: "Dr. Justin Thomas ",
    qualification: `MBBS, MD (Gen. Medicine), DM Neurology `,
    title: "Consultant ",
    experiences: [
      `4+ years of Clinical Practice in Neurology`,
      `Trained in Interventional Pain Management`,
    ],
    areas_of_expertise: [
      "Autoimmune Neurology",
      "Brain & spinal cord injury",
      "Dementia",
      "Epilepsy",
      "Headache disorders",
      "Movement Disorders",
      "Neuro-ophthalmology",
      "Neuromuscular disorders",
      "Pain management",
      "Sleep disorders",
      "Stroke ",
      "Vertigo",
    ],
    opd_timings: [],
  },


  // Dr. Arun Viswanathan
  // Orthopedics
  {
    id: 10,
    Image: doc10,
    department: "Orthopedics",
    name: "Dr. Arun Viswanathan",
    qualification: `MBBS, MS (Ortho)`,
    title: "Consultant ",
    experiences: [
      `4+ years of Clinical Practice in Neurology`,
      `Trained in Interventional Pain Management`,
    ],
    areas_of_expertise: [
      "Autoimmune Neurology",
      "Brain & spinal cord injury",
      "Dementia",
      "Epilepsy",
      "Headache disorders",
      "Movement Disorders",
      "Neuro-ophthalmology",
      "Neuromuscular disorders",
    ],
    opd_timings: [],
  },

  // Dr. Chandramohan K
  // Oncology
  {
    id: 63,
    Image: Dr_Chandramohan_K,
    department: "Oncology",
    name: "Dr. Chandramohan K",
    qualification: `MBBS, MS, MCh (Surgical Oncology)`,
    title: "Consultant ",
    experiences: [
      `>25 years as oncosurgeon`,
      `Best doctor award 2018-Kerala Govt.`,
      `Expert member of Global Forum of Oncosurgeons`,
      `Vice-president elect-indian Assn. of Surgical Oncology`,
    ],
    areas_of_expertise: [
      "Open & Minimally Invasive surgeries for: Gastro-intestinal cancers (liver, pancreas, colorectal, stomach)",
      "Open & Minimally Invasive surgeries for: Urological cancers (kidney, bladder)",
      "Open & Minimally Invasive surgeries for: Thoracic cancers (esophagus, lung)",
      "Open & Minimally Invasive surgeries for: Female genital cancers(ovary, uterus, cervix)",
      "Breast cancer - breast conservation surgeries, Oncoplastic surgeries",
      "Oral, Thyroid, Head & Neck cancers",
      "Skin cancer surgeries",
      "Musculoskeletal tumours & sarcomas",
    ],
    opd_timings: [],
  },

  // Dr Teena Nelson
  // Family Medicine
  {
    id: 64,
    Image: Dr_Teena_Nelson,
    department: "Oncology",
    name: "Dr Teena Nelson",
    qualification: "MBBS, DNB Radiation Oncology",
    title: "Consultant",
    experiences: [
      "2+ years of Oncology practice Trained in pain and palliative care",
    ],
    areas_of_expertise: [
      "Cancer screening & diagnosis",
      "Radiation Therapy: 3DCRT, IMRT, IGRT, VMAT, HDR",
      "brachytherapy",
      "Management of adult solid tumors",
      "Pain & Palliative care",
      "Management of Oncological Emergencies",
    ],
  },

  // Plastic Surgery
  // Dr. Vijaya Kumar
  {
    id: 15,
    Image: doc15,
    department: "Plastic Surgery",
    name: "Dr. Vijaya Kumar ",
  },
];

// Department
export const department = [
  // Orthopedics
  {
    id: 1,
    Image: De9,
    subImg: orthoz,
    icon: ico9,
    title: "Orthopedics",
    content: `Welcome to the Department of Orthopedics at SP Medifort, your trusted partner in musculoskeletal health. 
        Our team of our expert orthopedic surgeons is dedicated to providing cutting-edge care for a wide range of conditions, 
        from sports injuries to joint replacements. We believe in a holistic approach, combining advanced surgical techniques 
        with personalized rehabilitation plans. Our state-of-the-art facilities and innovative technologies enable us to offer 
        minimally invasive procedures, ensuring faster recovery times. Whether you're an athlete aiming to return to the field 
        or seeking relief from chronic pain, our compassionate professionals are committed to restoring your mobility and 
        enhancing your quality of life. Choose the Department of Orthopedics at SP Medifort, where expertise meets empathy.
        `,
  },
  // Pediatric Orthopedics
  {
    id: 2,
    Image: pediorth,
    subImg: pediorthoz,
    icon: ico13,
    title: "Pediatric Orthopedics",
    content: `At SP Medifort's Department of Pediatric Orthopedics, we understand that children's orthopedic needs are unique. Our specialized 
        team is trained to provide gentle, expert care for a wide spectrum of conditions, from congenital deformities to growth-plate injuries. 
        We create a warm, child-friendly environment to ensure your little one feels safe and comfortable. Our pediatric orthopedists work closely 
        with parents, using the latest diagnostic tools and treatment methods tailored to growing bodies. Whether it's clubfoot correction, or 
        fracture care, we're committed to safeguarding your child's skeletal development. Our goal is not just to treat but to educate, empowering 
        families with knowledge for optimal long-term outcomes. Trust SP Medifort's Pediatric Orthopedics for your child's bright, active future. `,
  },
  // Spine Surgery
  {
    id: 3,
    Image: De11,
    subImg: spinez,
    icon: ico11,
    title: "Spine Surgery",
    content: `Experience world-class care at SP Medifort's Department of Spine Surgery, where we're reshaping lives by 
        restoring spinal health. Our internationally recognized spine surgeons specialize in treating complex conditions 
        like herniated discs, spinal stenosis, and scoliosis. We believe in exhausting all conservative options before recommending 
        surgery. When an operation is necessary, our team employs the most advanced techniques, including robotic-assisted and 
        minimally invasive procedures, to ensure precision and reduce recovery time. Our multidisciplinary approach integrates 
        pain management, physical therapy, and psychological support for comprehensive healing. From degenerative diseases to 
        traumatic injuries, we're dedicated to relieving your pain and restoring your spine's natural function. Choose SP Medifort's 
        Spine Surgery for expert, compassionate care that stands straight above the rest.`,
  },
  // Plastic Surgery
  {
    id: 4,
    Image: De10,
    subImg: plasticz,
    icon: ico10,
    title: "Plastic Surgery",
    content: `Welcome to the Department of Plastic Surgery at SP Medifort, where artistry meets medical excellence. Our 
        board-certified plastic surgeons are not just doctors; they're skilled sculptors committed to enhancing your natural 
        beauty and restoring form after injury or disease. We offer a comprehensive range of cosmetic and reconstructive 
        procedures, from facelifts and breast augmentation to post-mastectomy reconstruction and cleft palate repair. Our 
        team takes time to understand your aesthetic goals or reconstructive needs, ensuring results that are both beautiful 
        and harmonious with your unique features. We utilize innovative techniques like fat grafting and 3D imaging for 
        optimal outcomes. At SP Medifort's Plastic Surgery, we believe in boosting confidence and facilitating healing, 
        always prioritizing your safety and satisfaction.`,
  },
  // Internal Medicine
  {
    id: 5,
    Image: De6,
    subImg: internalz,
    icon: ico6,
    title: "Internal Medicine",
    content: `At SP Medifort's Department of Internal Medicine, we are your partners in comprehensive adult healthcare. 
        Our seasoned internists are trained to be expert diagnosticians, adept at solving complex medical puzzles. We offer 
        a wide spectrum of services, from preventive care and health screenings to managing chronic conditions like diabetes, 
        hypertension, and autoimmune diseases. Our patient-centered approach means we don't just treat symptoms; we delve into 
        your lifestyle, genetics, and personal history to provide truly personalized care. We coordinate closely with specialists 
        when needed, ensuring seamless, integrated treatment plans. With a strong emphasis on patient education, we empower you 
        to actively participate in your health journey. Trust the Department of Internal Medicine at SP Medifort for compassionate, 
        thorough care that addresses your total well-being. We focus on diagnostic services and provide treatment for varied illnesses.
        `,
  },
  // Cardiology
  {
    id: 6,
    Image: De1,
    subImg: cardioz,
    icon: cardioIcon,
    title: "Cardiology",
    content: `Welcome to the SP Medifort Center for Cardiovascular Sciences, your premier destination for heart health. 
        Our world-class facilities are equipped to handle all cardiovascular diseases with exceptional care and expertise. We 
        specialize in cardio oncology and cardiac interventions in patients undergoing cancer treatments as well. We offer a 
        24x7 chest pain clinic with cardiologists available round the clock. Our state-of-the-art coronary care unit and 3D 
        hybrid CATH lab with surgical backup ensure comprehensive and advanced cardiac care and interventional cardiac procedures. 
        Our services include executive cardiac checkups, preventive cardiology sessions, and structured cardiac rehabilitation for 
        patients recovering from heart attacks, surgeries, and heart failure. Additionally, our outpatient facilities provide 
        specialized diagnostics, including ECG, 3D Echo, TMT, and more. At the SP Medifort Center for Cardiovascular Sciences, 
        we are dedicated to your heart health and well-being.`,
  },
  // Dermatology
  {
    id: 7,
    Image: De2,
    subImg: dermaz,
    icon: ico2,
    title: "Dermatology",
    content: `Welcome to the Department of Dermatology at SP Medifort, where healthy, radiant skin is our passion.
         Our board-certified dermatologists are experts in treating the full spectrum of skin, hair, and nail conditions. 
         From common issues like acne and eczema to more complex concerns such as psoriasis and skin cancer, we provide 
         comprehensive care. Our state-of-the-art facilities offer advanced treatments like Mohs surgery, laser therapy, 
         and biologics for optimal results. We also specialize in cosmetic dermatology, offering services like Botox, dermal 
         fillers, and chemical peels to rejuvenate your appearance. Our team understands that skin health reflects 
         overall well-being, so we take a holistic approach, considering factors like nutrition and stress. At SP Medifort's 
         Dermatology, we're committed to helping you feel comfortable and confident in your skin.
        `,
  },
  // Pediatrics
  {
    id: 8,
    Image: pedi,
    subImg: pediz,
    icon: ico14,
    title: "Pediatrics",
    content: `Welcome to our newly launched SP Medifort Center for Pediatrics, offering affordable and
        comprehensive child care. Under the guidance of the esteemed Dr. Vidya Vimal, MBBS, DCH,
        DNB, MNAMS, PGPN (Nutrition), our center is dedicated to providing top-tier pediatric services.
        Our offerings include a specialized immunization and nutrition clinic with special offers and child
        health checks. We provide expert care in pediatric nutrition, asthma, and allergies, as well as
        newborn care and well-baby checkups. Our services extend to pediatric vaccinations, growth
        and development monitoring, childhood illness management, and adolescent counseling.
        At the SP Medifort Center for Pediatrics, we are committed to nurturing the health and
        well-being of children at every stage of development.`,
  },
  // Family Medicine
  {
    id: 9,
    Image: fam,
    subImg: famz,
    icon: ico15,
    title: "Family Medicine",
    content: `At SP Medifort's Department of Family Medicine, we're more than healthcare providers; we're your family's 
        medical home. Our compassionate family physicians are trained to care for patients of all ages, from newborns to seniors. 
        We offer a wide range of services including preventive care, vaccinations, chronic disease management, and acute illness 
        treatment. What sets us apart is our commitment to understanding your family's unique dynamics, medical history, and lifestyle 
        factors. This comprehensive knowledge allows us to provide truly personalized care, fostering long-term relationships built on 
        trust. We coordinate seamlessly with specialists when needed, ensuring your family receives integrated, high-quality care. 
        Whether it's a child's growth check-up or an elder's diabetes management, SP Medifort's Family Medicine is here to guide your 
        family through every life stage, promoting health, longevity, and unity.
        `,
  },
  // ENT
  {
    id: 10,
    Image: De4,
    subImg: entz,
    icon: ico4,
    title: "ENT",
    content: `Experience unparalleled care at SP Medifort's Department of ENT, where we're dedicated to enhancing your senses. 
        Our skilled otorhinolaryngologists (ear, nose, and throat specialists) offer expert treatment for a wide array of conditions. 
        From common issues like sinusitis and tonsillitis to more complex concerns such as hearing loss and head and neck cancers, we
         provide comprehensive care. Our advanced audiology suite offers cutting-edge diagnostics and hearing aid fittings, while our 
         rhinology experts use image-guided systems for precise sinus surgeries. For voice and swallowing disorders, our speech pathologists 
         work closely with our surgeons. We also specialize in pediatric ENT, ensuring gentle care for your little ones. At SP Medifort's ENT, 
         we're committed to improving your ability to hear, breathe, taste, and communicate effectively, enriching your sensory experience of 
         the world.
        `,
  },
  // Neurosciences
  {
    id: 11,
    Image: De7,
    subImg: neuroz,
    icon: ico7,
    title: "Neurosciences",
    content: `At SP Medifort's Department of Neurology, we navigate the complexities of the nervous system with unmatched 
        expertise. Our team of renowned neurologists specializes in diagnosing and treating a broad spectrum of brain, spine, 
        and nerve disorders. Whether it's stroke, epilepsy, multiple sclerosis, or Parkinson's disease, we offer state-of-the-art 
        care. Our Neurovascular Unit provides rapid, lifesaving interventions for stroke, while our Epilepsy Center uses advanced 
        EEG and imaging to pinpoint seizure origins. For conditions like migraines and neuropathy, we offer innovative therapies 
        including Botox injections and nerve stimulation. We also have a dedicated Memory Clinic for dementia care. Our neurologists
        work closely with neurosurgeons, neuroradiologists, and rehabilitation specialists to provide comprehensive treatment. At SP 
        Medifort's Neurology, we're committed to unraveling neurological mysteries, restoring function, and improving your neurological health.`,
    list: [
      {
        title: `a) Neurosurgery:`,
        list: [
          {
            title: "Epilepsy surgery",
            content: `We offer comprehensive care for patients with refractory epilepsy. Led by experienced neurosurgeons 
                        and epileptologists, our multidisciplinary team conducts thorough evaluations to determine the most appropriate 
                        surgical approach for each individual. We utilize advanced neuroimaging techniques, such as MRI and PET scans, 
                        along with invasive monitoring procedures, to precisely localize seizure foci and identify candidates for surgery. 
                        Surgical options may include resective surgery, such as temporal lobectomy or lesionectomy, as well as neuromodulation 
                        techniques like vagus nerve stimulation (VNS) or deep brain stimulation (DBS). Our goal is to provide tailored treatment 
                        plans that reduce seizure frequency and improve quality of life for patients with epilepsy.`,
          },
          {
            title: "Vascular Surgery",
            content: `Our vascular surgery department specializes in the diagnosis and treatment of conditions affecting the blood 
                        vessels throughout the body. Led by experienced vascular surgeons, our team offers comprehensive care for arterial 
                        and venous disorders, including peripheral artery disease, aortic aneurysms, varicose veins, and deep vein thrombosis. 
                        We utilize advanced imaging techniques and minimally invasive procedures, such as angioplasty, stenting, and endovascular 
                        repair, to restore blood flow and improve circulation. Our goal is to provide personalized treatment plans that address 
                        each patient's unique vascular needs, reducing symptoms and minimizing complications`,
          },
          {
            title: "Craniotomy",
            content: `At SP Medifort, our neurosurgery department specializes in craniotomy procedures, which involve surgical access 
                        to the brain through the skull. Led by experienced neurosurgeons, our team utilizes advanced techniques and state-of-the-art 
                        technology to perform craniotomies for a wide range of neurological conditions, including brain tumors, aneurysms, vascular 
                        malformations, and traumatic brain injuries. We prioritize patient safety and comfort throughout the procedure, employing 
                        precise surgical planning and intraoperative monitoring to ensure optimal outcomes. Our goal is to provide personalized 
                        treatment plans that address each patient's specific needs, while maximizing the potential for recovery and improving overall q
                        uality of life.`,
          },
        ],
      },
      {
        title: `b) Neurology:`,
        list: [
          {
            title: "EMG:",
            content: `Our neurology department offers electromyography (EMG) services to diagnose and evaluate conditions affecting the muscles 
                        and nerves. Led by experienced neurologists and electromyography, our team utilizes advanced EMG techniques to assess muscle function, 
                        nerve conduction, and electrical activity within the body. EMG testing may be used to diagnose conditions such as peripheral neuropathy, 
                        muscular dystrophy, myasthenia gravis, and carpal tunnel syndrome. Our goal is to provide accurate and timely diagnosis, guiding 
                        treatment decisions and optimizing patient care.`,
          },
          {
            title: "Nerve Conduction Study:",
            content: `At SP Medifort's Neurology Department, we offer advanced diagnostic services, including nerve conduction studies (NCS), 
                        to assess nerve function and identify neurological disorders. Led by expert neurologists, our NCS procedures utilize state-of-the-art 
                        equipment to measure electrical impulses along nerves, helping to diagnose conditions such as carpal tunnel syndrome, peripheral neuropathy, 
                        and nerve injuries. With a commitment to precision and patient comfort, our team ensures accurate results and timely interpretation, guiding 
                        treatment decisions and optimizing patient care. SP Medifort's Neurology Department is dedicated to providing comprehensive neurological evaluations 
                        and diagnostic services to improve the health and well-being of our patients.`,
          },
        ],
      },
    ],
  },
  // General Surgery
  {
    id: 12,
    Image: De5,
    subImg: gensurgz,
    icon: ico5,
    title: "General Surgery",
    content: `Welcome to the Department of General Surgery at SP Medifort, where surgical excellence meets 
        compassionate care. Our team of board-certified surgeons has vast experience in a wide range of procedures, 
        from appendectomies and hernia repairs to complex gastrointestinal surgeries. We specialize in surgical oncology, 
        offering advanced techniques for liver, pancreatic, and colorectal cancers. Our Breast Care Center provides 
        comprehensive services from biopsy to reconstruction. We're also experts in endocrine surgery, performing 
        delicate thyroid and parathyroid operations. Using the latest in minimally invasive techniques, we ensure faster 
        recovery times and better outcomes. Our surgeons work closely with anesthesiologists, nurses, and pain specialists 
        to ensure your comfort and safety. At SP Medifort's General Surgery, we blend technical prowess with a personal 
        touch, guiding you through every step of your surgical journey.
        `,
  },
  // Robotic & Laparoscopic General Surgery
  {
    id: 13,
    Image: robot,
    subImg: roboz,
    icon: ico16,
    title: "Robotic & Laparoscopic General Surgery",
    content: `Step into the future of surgery at SP Medifort's Department of Robotic & Laparoscopic General Surgery. 
        We're at the forefront of minimally invasive techniques, using advanced robotic systems like da Vinci and laparoscopic 
        tools to perform a wide array of procedures. This technology allows our skilled surgeons to make tiny incisions, resulting 
        in less pain, faster healing, and minimal scarring. Our robotic expertise shines in complex operations like 
        colorectal resections, bariatric surgeries, and hernia repairs with mesh. For procedures like gallbladder removal 
        and appendectomy, our laparoscopic approach offers quick recovery. The robotic system's 3D visualization 
        and articulated instruments provide unparalleled precision, especially in tight spaces. Our team continually 
        trains in these cutting-edge methods to offer you the best. Choose SP Medifort's Robotic & Laparoscopic General 
        Surgery for procedures that are gentler on your body, without compromising on quality.
        `,
  },
  // Urology
  {
    id: 14,
    Image: De12,
    subImg: uroz,
    icon: ico12,
    title: "Urology",
    content: `At SP Medifort's Department of Urology, we're dedicated to providing superior care for all 
        aspects of urological health. Our team of expert urologists offers comprehensive treatment for conditions 
        affecting the urinary tract and male reproductive system. From common issues like kidney stones and UTIs 
        to more complex concerns such as prostate cancer and erectile dysfunction, we provide advanced, compassionate care. 
        Our state-of-the-art facilities feature the latest in diagnostic tools and treatment options. We specialize in
        minimally invasive procedures like laparoscopic kidney surgery, robotic prostatectomy, TURP (Transurethral 
        resection of the prostate), URS, RIRS (Retrograde intrarenal surgery) and PCNL (Percutaneous nephrolithotomy). 
        Our continence clinic offers innovative therapies for bladder control issues, while our men's health center 
        addresses concerns like infertility and low testosterone. We also have a dedicated pediatric urologist for 
        conditions like bedwetting and undescended testicles. At SP Medifort's Urology, we combine clinical excellence 
        with sensitivity, helping you maintain urological wellness with dignity and comfort.`,
  },
  // Oncology
  {
    id: 15,
    Image: De8,
    subImg: oncz,
    icon: ico8,
    title: "Oncology",
    content: `Welcome to the Department of Oncology, where compassionate and comprehensive cancer
        care is our priority. Our specialized chemotherapy ward features private cubicles and day care
        beds, ensuring comfort during treatment. We offer weekend outpatient departments (OPDs) for
        convenient chemotherapy sessions and consultations.
        Our multidisciplinary tumor board collaborates to create personalized treatment plans,
        supported by thorough guidance and counseling. We specialize in both adult and pediatric
        oncology, offering advanced therapies like immunotherapy and targeted therapy. With affordable
        treatment plans, we ensure accessible high-quality care.
        Enhancing the healing environment, we provide aroma and music therapy. Our skilled oncology
        nurses deliver expert, compassionate care to meet all patient needs. Your well-being is our
        mission.`,
  },
  // Emergency Medicine
  {
    id: 16,
    Image: De3,
    subImg: emergz,
    icon: ico3,
    title: "Emergency Medicine",
    content: `In life's most critical moments, SP Medifort's Department of Emergency Medicine stands ready. Our team of board-certified emergency physicians, 
        trauma surgeons, and critical care nurses is highly trained to handle any crisis, 24/7. From heart attacks and strokes to severe injuries and poisonings, 
        we provide rapid, expert care when seconds count. Our state-of-the-art ER is equipped with advanced technology like point-of-care ultrasound and digital 
        radiography for swift, accurate diagnoses. We have dedicated trauma bays, a cardiac catheterization lab on standby, and a stroke unit for immediate interventions. 
        Our triage system ensures the most urgent cases are seen first, while our compassionate staff keeps all patients comfortable and informed. We also 
        excel in pediatric emergencies, with specialists trained to soothe and treat our youngest patients. At SP Medifort's Emergency Medicine, we blend speed, 
        skill, and empathy to save lives and foster recovery in your darkest hour.
        `,
  },
  // Anesthesia
  {
    id: 17,
    Image: anes,
    subImg: anaesz,
    icon: ico17,
    title: "Anesthesia",
    content: `At SP Medifort's Department of Anesthesia, we are the unseen guardians of your surgical journey. Our team of expert anesthesiologists 
        and anesthetic technicians is dedicated to ensuring your safety, comfort, and well-being before, during, and after your procedure. We offer 
        a full spectrum of anesthetic services, tailoring each plan to your unique needs, whether it's general anesthesia for major surgery or regional 
        anesthesia for joint replacement. Our expertise extends to pediatric and cardiac, ensuring specialized care for every patient. We also have the best 
        department of terminology and we address all types of traumas. Using advanced monitoring technology, we closely track your vital signs, making real-time 
        adjustments for optimal stability. We're also leaders in pain management, offering innovative techniques like nerve blocks and patient-controlled analgesia 
        for post-operative comfort. Beyond the OR, we're key members of the critical care team. At SP Medifort's Anesthesia, we provide peace of mind, allowing you 
        to rest easy while we vigilantly oversee your care.`,
  },
  // Radiodiagnosis
  // {
  //     id: 18,
  //     Image: rad,
  //     subImg: radz,
  //     icon: ico18,
  //     title: "Radiodiagnosis",
  //     content: `Welcome to SP Medifort's Department of Radiodiagnosis, where cutting-edge technology meets diagnostic excellence. Our team of board-certified
  //     radiologists specializes in interpreting a wide array of medical images to uncover the root of your health concerns. We house the latest imaging equipment,
  //     including high-resolution CT scanners, 3T MRI machines, digital X-ray, and advanced ultrasound systems. For cardiac and vascular issues, our cardiac CT and
  //     interventional radiology suite offers detailed insights. We excel in neuroradiology, using functional MRI to map brain activity, and in women's imaging, providing
  //     3D mammography and breast MRI. Our PET/CT scanner is crucial for cancer staging and treatment monitoring. We're committed to safety, using low-dose
  //     protocols and MRI techniques that reduce radiation exposure. Our radiologists collaborate closely with your doctors, providing detailed, timely reports.
  //     At SP Medifort's Radiodiagnosis, we transform complex images into clear answers, guiding your path to accurate diagnosis and effective treatment.`,
  // },

  // Critical Care
  {
    id: 19,
    Image: critic,
    subImg: criticz,
    icon: ico19,
    title: "Critical Care",
    content: `In the most challenging health crises, SP Medifort's Department of Critical Care stands as your beacon of hope. Our multidisciplinary team, 
        including physiotherapists, dietitians, intensivists, specialized nurses, respiratory therapists, and pharmacists, works in perfect synergy to care for 
        critically ill patients. We manage a wide range of life-threatening conditions, like ARDS, septic shock, etc. We also help in managing toxicology emergencies 
        and severe polytrauma. Our cutting-edge ICU is equipped with advanced life support systems, including ECMO and continuous renal replacement therapy. We use 
        sophisticated monitoring to track every vital parameter, allowing for immediate interventions. We diagnose complex cases and provide therapeutic interventions  
        and help in rehabilitation of our patients. We carry out procedures like ICDs and pigtail insertions as well. At our institute, we prioritize human 
        touches—involving families in care plans, providing psychological support, and even offering music therapy. At SP Medifort's Critical Care, we combine 
        technological prowess with heartfelt compassion, fighting tirelessly to help you overcome your most severe health challenges `,
  },
  // ICU
  {
    id: 20,
    Image: icu,
    subImg: icuz,
    icon: ico20,
    title: "ICU",
    content: `At SP Medifort's ICU, we provide an unparalleled level of care for patients requiring continuous monitoring and life support. Our state-of-the-art unit is designed 
        for optimal patient oversight, with a high staff-to-patient ratio ensuring personalized attention. Each bed is equipped with advanced technology, from ventilators and infusion 
        pumps to hemodynamic monitors, allowing us to support every critical function. Our ICU team is highly specialized—respiratory therapists manage ventilators, while our 
        critical care nurses are experts in procedures like central line care. We have dedicated sections: the CICU for heart patients, NICU for newborns, SICU for post-surgical 
        care, and MICU for complex medical conditions. Our intensivists lead daily multidisciplinary rounds, fine-tuning treatment plans. We also focus on preventing complications 
        like VAP and sepsis. Recognizing the ICU's emotional toll, we offer robust family support services. At SP Medifort's ICU, we provide round-the-clock, expert care, creating 
        a safety net that supports you through your most vulnerable moments.`,
  },
  // Pediatric Surgery
  {
    id: 21,
    Image: pedsurg,
    subImg: pedsurgz,
    icon: ico21,
    title: "Pediatric Surgery",
    content: `The Center for Paediatric Surgery provides care for children from newborn period to adolescence. 
        The division deals with all the common surgical disorders in children like Inguinal Hernia and Hydrocele, Undescended Testis, 
        Phimosis etc. Surgical emergencies in children like Acute Appendicitis, Acute Intussusception,  Acute scrotum and Foreign body 
        ingestion are treated in the division. The Center offers the best facilities for Ultrasound guided hydrostatic 
        reduction of Intussusception, Minimally invasive and Endoscopic surgery in children.

        The Center for Paediatric Urology & Genitourinary Surgery, led by the Paediatric Surgeon, and involving a multidisciplinary team, deals 
        with all the common Paediatric Urological problems in infants & children. The Center specifically specializes in surgery for Hypospadias
         and complex Genitourinary anomalies. Surgery for older children and adults with untreated Hypospadias and Reoperation for 
         previously failed repairs are performed here. The vastly experienced surgical team aims to offer the most scientific, dedicated 
         and exclusive care to these children.

        The other key attributes of the Department are the conduct of specialty clinics for pregnant mothers with Antenatally detected 
        fetal anomalies, Voiding disorders & Neurogenic Bladder in children and Cleft lip & palate.

        A collaborative and interdisciplinary approach with scientific & protocolised therapy aims to provide the youngest of all the 
        surgical patients with the most effective therapy in the most considerate and compassionate manner.

        Paediatric Urological conditions commonly treated: Urinary Tract Infection (UTI) in children, Vesico Uretetric Reflux (VUR), 
        Hydronephrosis, PUJ obstruction (PUJO), Posterior Urethral Valves (PUV), Hypospadias, Voiding disorders, Neurogenic Bladder 
        and Antenatally detected fetal hydronephrosis. The center is equipped with advanced facilities for endourological surgery.

        `,
  },
  // Physiotherapy
  {
    id: 22,
    Image: physio,
    subImg: physioz,
    icon: ico22,
    title: "Physiotherapy",
    content: `Welcome to The Department Of Physiotherapy at SP Medifort, your premier destination for
        comprehensive rehabilitation and condition management. Our dedicated team is committed to
        transforming your physiotherapy experience through an exceptional person-centric approach.
        We specialize in rehabilitation and attending to conditions such as pain management and
        post-operative rehabilitation. Our team consists of highly skilled professionals who are
        passionate about providing personalized care tailored to your individual needs.
        We pride ourselves on our unparalleled dedication to our patients, with an emphasis on
        evidence-based care. Our goal is to restore fearless, natural, and painless movement, helping
        you regain independence and the best quality of life.
        Experience the difference with The Department Of Physiotherapy at SP Medifort, where your
        well-being is our top priority.`,
  },
  // Endocrinology
  {
    id: 23,
    Image: De13,
    subImg: De13Sub,
    icon: ico23,
    title: "Endocrinology",
    content: `SP Medifort's Endocrinology Department is dedicated to providing comprehensive care for patients with hormonal 
        and metabolic disorders. Led by expert endocrinologists, our department offers specialized services for conditions such as 
        diabetes, thyroid disorders, adrenal gland disorders, and hormonal imbalances. Through advanced diagnostic testing, personalized 
        treatment plans, and patient education, we aim to optimize hormone levels, manage symptoms, and improve overall health and well-being. 
        With a multidisciplinary approach and state-of-the-art facilities, SP Medifort's Endocrinology Department is committed to delivering 
        exceptional care and empowering patients to lead healthier lives.`,
  },
  // Radiology
  {
    id: 24,
    Image: De14,
    subImg: De14Sub,
    icon: ico18,
    title: "Radiology",
    content: `Welcome to SP Medifort's Department of Radiodiagnosis, where cutting-edge technology meets diagnostic excellence. 
        Our team of board-certified radiologists specializes in interpreting a wide array of medical images to uncover the root of your 
        health concerns. We house the latest imaging equipment, including high-resolution CT scanners, 3T MRI machines, digital 
        X-ray, and advanced ultrasound systems. For cardiac and vascular issues, our cardiac CT and interventional radiology 
        suite offers detailed insights. We excel in neuroradiology, using functional MRI to map brain activity, and in women's 
        imaging, providing 3D mammography and breast MRI. Our PET/CT scanner is crucial for cancer staging and treatment monitoring. 
        We're committed to safety, using low-dose protocols and MRI techniques that reduce radiation exposure. Our radiologists collaborate 
        closely with your doctors, providing detailed, timely reports. At SP Medifort's Radiodiagnosis, we transform complex images into clear answers, 
        guiding your path to accurate diagnosis and effective treatment.`,
  },
  // Nephrology  
  {
    id: 25,
    Image: Nephro,
    subImg: Nephro,
    icon: ico24,
    title: "Nephrology",
    content: `Welcome to the Department of Nephrology at SP Medifort, where we specialize in comprehensive kidney care. Our team of highly skilled nephrologists is dedicated to diagnosing and treating a broad spectrum of kidney-related conditions, from acute kidney injuries to chronic kidney disease. We take pride in our patient-centered approach, offering personalized treatment plans that combine the latest medical advancements with compassionate care. With access to cutting-edge diagnostics, dialysis services, and innovative therapies, we strive to improve kidney health and overall well-being. Whether you're managing kidney disease or seeking preventive care, our experts are here to support you every step of the way. At the Department of Nephrology, SP Medifort, your kidney health is our priority.`,
  },
  //  Laryngology 
  {
    id: 26,
    Image: Laryngo,
    subImg: Laryngo,
    icon: ico25,
    title: "Laryngology",
    content: `Welcome to the Department of Laryngology at SP Medifort, your trusted partner in ear, nose, and throat health. Our team of expert laryngologists is dedicated to delivering comprehensive care for a wide range of voice, airway, and throat disorders. We combine state-of-the-art diagnostics with advanced surgical techniques to provide the best possible outcomes. Whether you're dealing with vocal cord issues, breathing difficulties, or chronic throat conditions, we offer personalized treatment plans designed to meet your unique needs. Our minimally invasive procedures and cutting-edge technologies ensure faster recovery times, allowing you to return to your daily activities with improved comfort and confidence. At the Department of Laryngology at SP Medifort, we believe in blending medical expertise with compassionate care to enhance your quality of life. Choose us for specialized, empathetic treatment in all aspects of laryngology.`,
  },
  // Respiratory Medicine  
  {
    id: 27,
    Image: ResSub,
    subImg: Respiratory,
    icon: ico26,
    title: "Respiratory Medicine",
    content: `Welcome to the Department of Respiratory Medicine at SP Medifort, your trusted partner in lung and respiratory health. Our team of expert pulmonologists is dedicated to providing comprehensive care for a wide range of respiratory conditions, from asthma and chronic obstructive pulmonary disease (COPD) to complex lung disorders. We believe in a patient-centered approach, combining advanced diagnostics with personalized treatment plans. Our state-of-the-art facilities and innovative therapies allow us to offer minimally invasive procedures, ensuring more effective management and faster recovery. Whether you’re managing a chronic condition or seeking relief from acute respiratory issues, our compassionate specialists are committed to improving your breathing and enhancing your quality of life. Choose the Department of Respiratory Medicine at SP Medifort, where expertise meets empathy.`,
  },
];

// Treatment
export const treatments = [
  // Orthopedics
  {
    department: "Orthopedics",
    list: [
      {
        name: `Orthopedics`,
        details: `At SP Medifort, we're dedicated to revolutionizing orthopaedic care with our highly skilled and specialized team of healthcare professionals. 
                Comprising top-tier doctors, nurses, and physical therapists, we adopt a holistic approach to diagnosing and treating orthopaedic conditions. Our 
                team boasts expertise across multiple subspecialties, including spine, foot and ankle, paediatric care, hand and arm, shoulder, and sports medicine. 
                Each member is committed to delivering personalized and comprehensive care tailored to the individual needs of our patients. With a focus on both 
                surgical and non-operative interventions, our clinicians are at the pinnacle of their respective fields. From addressing spinal injuries to performing 
                joint replacements, sports injuries, severe trauma surgeries and orthopaedic surgeries, we ensure that every patient receives unparalleled expertise 
                and support. As a recognized center of excellence, we lead the way in joint replacement surgery, empowering countless individuals to reclaim pain-free 
                lives. At SP Medifort, we're not just treating conditions; we aim at achieving results with pain free movements. We continue to transform lives with 
                exceptional care and groundbreaking solutions`,
      },
      {
        name: `Joint Replacement Surgery`,
        details: `At SP Medifort, our Joint Replacement Surgery Department is dedicated to restoring mobility and improving quality of life for patients suffering 
                from debilitating joint conditions. Led by a team of renowned orthopedic surgeons and specialists, we offer state-of-the-art treatments and innovative 
                surgical techniques tailored to each patient's needs. From hip and knee replacements to shoulder and elbow procedures, our comprehensive approach encompasses 
                thorough preoperative assessments, personalized treatment plans that are affordable for all patients, and attentive postoperative care. We pledge a commitment 
                to excellence and a focus on patient-centered outcomes.`,
      },
      {
        name: `Minimally Invasive Surgeries For Trauma`,
        details: `At SP Medifort, we lead the way in minimally invasive orthopedic surgeries, revolutionizing treatment options for patients with musculoskeletal conditions. 
                Our expert team of orthopedic surgeons specializes in state-of-the-art techniques that minimize incisions, reduce trauma to surrounding tissues, and accelerate 
                recovery times. From arthroscopic procedures for joint conditions to minimally invasive spine surgeries, our approach prioritizes precision and patient well-being. 
                By combining advanced technology with personalized care, SP Medifort ensures that patients experience less pain, shorter hospital stays, and faster return to daily 
                activities. Trust SP Medifort for cutting-edge minimally invasive orthopedic care that prioritizes your comfort and recovery.`,
      },
      {
        name: `Spine Centre`,
        details: `Welcome to the Spine Center at SP Medifort Hospital, where technical precision and
                comprehensive care converge to address a spectrum of spinal conditions. Our
                multidisciplinary team comprises leading spine surgeons, neurologists, physiotherapists,
                and pain management specialists, all dedicated to delivering cutting-edge treatments
                with meticulous attention to detail. Utilizing state-of-the-art imaging technologies such as
                MRI and CT scans, we ensure precise diagnosis of spinal disorders, including
                degenerative disc disease, spinal stenosis, and spinal tumors. From minimally invasive
                spine surgery techniques to advanced rehabilitation protocols, our integrated approach
                aims to optimize patient outcomes while minimizing surgical risks and enhancing
                recovery times. At SP Medifort's Spine Center, we're committed to advancing spinal care
                through innovation, research, and a steadfast dedication to excellence.`,
      },
    ],
  },

  // Pediatric Orthopedics
  {
    department: "Pediatric Orthopedics",
    list: [
      {
        name: `Clubfoot Surgery`,
        details: `Utilizing advanced surgical techniques and personalized care plans, our experienced
                team of pediatric orthopedic surgeons provides precise and effective interventions to
                correct clubfoot deformities in children. From meticulous preoperative evaluations to
                postoperative rehabilitation, we are committed to ensuring optimal outcomes and
                restoring mobility and function for young patients. At SP Medifort, parents can trust that
                their child's clubfoot treatment is in expert hands, with a compassionate approach that
                prioritizes their well-being and long-term health.`,
      },
      {
        name: `Growth Abnormalities`,
        details: `At SP Medifort's Pediatric Orthopedics Department, we specialize in the precise
                management of growth abnormalities in children. Led by a team of skilled pediatric
                orthopedic surgeons and specialists, we offer comprehensive care tailored to each
                child's unique needs. Utilizing advanced diagnostic tools and evidence-based treatment
                approaches, we address a range of growth-related conditions, from limb length
                discrepancies to skeletal dysplasias. Whether through non-surgical interventions such as
                bracing and physical therapy or surgical procedures like guided growth techniques, our
                goal is to optimize growth and development while minimizing discomfort and maximizing
                functional outcomes. At SP Medifort, we're dedicated to ensuring that every child
                receives the specialized care they need to thrive and achieve their full potential.`,
      },
      {
        name: `Orthopedic Conditions in Children`,
        details: `Treating common orthopedic conditions in children like bowlegs, knock knees, intoeing, 
                and toe walking at SP Medifort involves a multidisciplinary approach aimed at addressing both the physical and developmental 
                aspects. Orthopedic specialists work closely with pediatricians, physical therapists, and sometimes orthotists to tailor 
                treatment plans suited to each child's unique needs. From simple monitoring and reassurance for mild cases to more intensive 
                interventions such as bracing, physical therapy, or even surgical correction for severe cases, the goal remains ensuring optimal 
                musculoskeletal health and function as the child grows. `,
      },
      {
        name: `Fractures in children`,
        details: `At SP Medifort, treating fractures in children involves specialized care designed to address the unique needs of young patients. 
                Our expert orthopedic team employs advanced diagnostic techniques and evidence-based treatment approaches to ensure optimal healing and recovery. 
                From simple fractures requiring immobilization with casts or splints to more complex fractures necessitating surgical intervention, 
                every case is managed with precision and compassion. Our pediatric-focused approach emphasizes not only restoring mobility and function 
                but also minimizing potential long-term effects on growth and development. `,
      },
      {
        name: `Management of Orthopedic Congenital Disorders`,
        details: `At SP Medifort, the management of orthopedic congenital disorders, including clubfoot, hip dislocation, and hemimelias, is 
                approached with expertise and compassion. Our specialized team of orthopedic surgeons utilizes state-of-the-art techniques tailored to 
                each patient's unique needs. From early diagnosis to comprehensive treatment plans, we prioritize restoring functionality and improving 
                quality of life. Through a blend of surgical intervention, innovative therapies, and dedicated rehabilitation, we strive to address the 
                complexities of these conditions, empowering patients to thrive`,
      },
      {
        name: `Pediatric Bone and Joint Infections`,
        details: `Treating pediatric bone and joint infections at SP Medifort is a comprehensive endeavor that prioritizes both efficacy and compassion. Our specialized team 
                of pediatric orthopedic experts employs a multidisciplinary approach, combining advanced medical interventions with a supportive environment for children and their 
                families. From meticulous diagnosis utilizing cutting-edge imaging technologies to tailored antibiotic regimens and, when necessary, minimally invasive surgical 
                interventions, our focus remains on restoring health and function while minimizing discomfort and disruption to a child's life. With a commitment to individualized 
                care and a dedication to ongoing research and innovation, SP Medifort stands at the forefront of pediatric bone and joint infection treatment, 
                ensuring the best possible outcomes for every young patient entrusted to our care.`,
      },
      {
        name: `Deformity Correction`,
        details: `Pediatric deformity correction procedures at SP Medifort embody our commitment to excellence in pediatric orthopedic care. With a specialized focus on young 
                patients, our team combines expertise, innovation, and empathy to address a wide spectrum of musculoskeletal abnormalities. From congenital conditions to 
                developmental deformities, we utilize advanced imaging technologies and pioneering surgical techniques tailored to each child's unique needs. Our holistic 
                approach encompasses not only surgical intervention but also comprehensive pre-operative assessments and post-operative rehabilitation, ensuring optimal 
                outcomes and long-term well-being.`,
      },
      {
        name: `Limb Lengthening`,
        details: `At SP Medifort, pediatric limb lengthening procedures are conducted with precision, compassion, and a focus on the unique needs of growing children. Our specialized 
                team of orthopedic experts utilizes innovative techniques to address limb length inequalities caused by various conditions, ensuring optimal skeletal alignment and function. 
                From meticulous pre-operative planning to tailored surgical interventions and comprehensive post-operative care, we prioritize the well-being and comfort of our young 
                patients every step of the way. With a commitment to personalized treatment and ongoing support, SP Medifort strives to empower children to reach their full potential, 
                fostering mobility, confidence, and a brighter future.`,
      },
      {
        name: `Hip Disorders`,
        details: `At SP Medifort, treating pediatric hip disorders such as Perthes disease, Slipped Capital Femoral Epiphysis (SCFE), and Cerebral Palsy reflects our dedication 
                to comprehensive and specialized care for young patients. Our orthopedic team combines expertise with compassion, employing advanced diagnostic tools and innovative 
                treatment strategies tailored to each child's specific condition. Whether it's the careful management of Perthes disease to preserve hip joint integrity, 
                he precise surgical intervention for SCFE to prevent further complications, or the multidisciplinary approach to address hip involvement in Cerebral Palsy, 
                our goal remains consistent: to optimize mobility, function, and quality of life for every child. `,
      },
      {
        name: `Club Foot`,
        details: `At SP Medifort, treating pediatric clubfoot is approached with expertise, empathy, and a commitment to lifelong mobility. Our dedicated team of 
                orthopedic specialists utilizes a comprehensive treatment protocol tailored to each child's unique needs. From gentle manipulation and casting techniques in infancy 
                to surgical intervention for more severe cases, our goal is to achieve optimal foot alignment and function. We prioritize early intervention to minimize discomfort 
                and promote proper development, working closely with families to ensure continuity of care and support. With a focus on both short-term correction and 
                long-term outcomes, SP Medifort strives to empower children with clubfoot to lead active, fulfilling lives, free from the limitations of this congenital condition. `,
      },
      {
        name: `Management of congenital malformations`,
        details: `Our expert team of pediatric orthopedic surgeons and specialists is dedicated to
                providing personalized care tailored to each child's unique needs. Utilizing advanced
                diagnostic techniques and innovative treatment modalities, we address a wide range of
                congenital orthopedic conditions, including clubfoot, congenital hip dysplasia, limb length
                discrepancies, and skeletal deformities. From early diagnosis to specialized surgical
                interventions and ongoing rehabilitation, we are committed to optimizing function,
                mobility, and quality of life for our young patients.`,
      },
    ],
  },

  // Spine Surgery
  {
    department: "Spine Surgery",
    list: [
      {
        name: `Discectomy`,
        details: `At SP Medifort, we offer advanced discectomy treatments to address spinal disc issues
                with precision and care. Our skilled team of spine specialists utilizes state-of-the-art
                techniques to alleviate pain and improve mobility for patients suffering from herniated or
                degenerated discs. With a focus on minimally invasive procedures and personalized
                care, we strive to provide effective relief and restore quality of life for our patients.`,
      },
      {
        name: `Spinal Fusion`,
        details: `At SP Medifort, we specialize in spinal fusion treatments to address a variety of spinal
                conditions with precision and expertise. Spinal fusion is a surgical procedure aimed at
                stabilizing the spine by joining two or more vertebrae together. Whether treating
                conditions such as spinal instability, degenerative disc disease, or spinal deformities, our
                goal is to relieve pain, improve spinal alignment, and restore function. With a focus on
                patient-centered care and comprehensive rehabilitation, SP Medifort is dedicated to
                helping our patients regain mobility and enjoy a better quality of life through our
                specialized spinal fusion treatment.`,
      },
      {
        name: `Laminectomy`,
        details: `Our laminectomy procedure is a surgical intervention designed to alleviate pressure on
the spinal cord or nerves caused by spinal stenosis or other spinal conditions. Our team
of skilled neurosurgeons and orthopedic spine specialists utilizes advanced techniques
and state-of-the-art technology to perform laminectomy procedures with precision and
care. Whether it's addressing symptoms such as pain, numbness, or weakness, our goal
is to improve spinal function and enhance quality of life for our patients.`,
      },
      {
        name: `Spinal tumors`,
        details: `At SP Medifort, we specialize in the treatment of spinal tumors, offering tailored care to
address the individual needs of each patient. Our dedicated team of neurosurgeons and
oncologists employs cutting-edge diagnostic techniques and advanced therapies to
effectively manage spinal tumors, whether benign or malignant. Through a
multidisciplinary approach that may include surgery, radiation, chemotherapy, and
supportive therapies, we strive to achieve the best possible outcomes for our patients. At
SP Medifort, we are committed to providing compassionate care and personalized
treatment plans that prioritize the well-being and recovery of those affected by spinal
tumors.`,
      },
      {
        name: `Cervical spine surgeries`,
        details: `We provide advanced treatments to address a range of cervical spine conditions with
precision and expertise. Led by a team of highly skilled neurosurgeons and orthopedic
spine specialists, we utilize state-of-the-art techniques and innovative technology to
perform cervical spine surgeries tailored to each patient's specific needs. Whether
treating cervical disc herniation, spinal stenosis, or cervical spine fractures, our goal is to
relieve pain, restore function, and improve quality of life. With a focus on minimally
invasive approaches and personalized care, SP Medifort is dedicated to providing the
highest standard of cervical spine surgery treatment, ensuring optimal outcomes for our
patients.`,
      },
      {
        name: `Spinal Decompression`,
        details: `At SP Medifort, we excel in spinal decompression treatments, specializing in relieving
pressure on the spinal nerves and cord to alleviate associated symptoms effectively. Our
skilled team of neurosurgeons and orthopedic specialists utilizes state-of-the-art
techniques and advanced technology to customize spinal decompression procedures for
each patient's specific condition. Whether targeting spinal stenosis, herniated discs, or
other spinal issues, our primary aim is to enhance mobility, diminish discomfort, and
improve overall quality of life. With a commitment to personalized care and precision, SP
Medifort ensures that every spinal decompression treatment is tailored to prioritize the
well-being and sustained health of our patients. Count on us to accompany you on your
journey to spinal wellness and recovery with expertise and compassion.`,
      },
    ],
  },

  // Plastic Surgery
  {
    department: "Plastic Surgery",
    list: [
      {
        name: `Trauma Surgeries:`,
        details: `The Plastic Surgery Department at SP is a beacon of hope for patients 
                grappling with the aftermath of trauma. Here, skilled surgeons adeptly navigate the 
                intricacies of reconstructive and vascular surgeries, restoring not just physical form 
                but also dignity and confidence. Whether it's repairing facial trauma, rebuilding shattered 
                limbs, or meticulously restoring damaged tissues, every procedure is a testament to the 
                department's commitment to excellence and compassion. With cutting-edge techniques and unwavering 
                dedication, they transform adversity into opportunity, empowering patients to embrace life anew.`,
      },
      {
        name: `Replant and Revascularization surgery:`,
        details: `Our Plastic Surgery Department offers specialized care in replant and revascularization surgery, 
                providing hope and restoration for patients who have experienced traumatic limb injuries or amputations. 
                Our skilled plastic surgeons are trained in advanced microsurgical techniques, enabling them to meticulously 
                reconnect blood vessels, nerves, and tissues to restore blood flow and function to injured limbs. 
                Through replant and revascularization surgery, we aim to salvage and restore the affected limb, 
                preserving both its form and function. Our multidisciplinary team works collaboratively to assess each 
                patient's unique situation and develop a personalized treatment plan tailored to their needs.
                With state-of-the-art facilities and a commitment to excellence, SP Medifort is dedicated to providing 
                compassionate and comprehensive care to patients undergoing replant and revascularization surgery in our 
                Plastic Surgery Department.`,
      },
      {
        name: `Brachial plexus surgery:`,
        details: `In brachial plexus surgery, offering advanced solutions for patients with complex nerve injuries 
                affecting the upper extremities. Our skilled plastic surgeons are experts in microsurgical techniques, enabling 
                precise nerve repair and reconstruction to restore function and sensation to the affected arm. Through comprehensive 
                evaluation and personalized treatment plans, we aim to optimize outcomes and improve quality of life for patients 
                with brachial plexus injuries. Our multidisciplinary team collaborates closely to provide compassionate care and 
                support throughout every step of the surgical journey.`,
      },
      {
        name: `Congenital anomaly:`,
        details: `At SP Medifort, our expert surgeons in the Plastic Surgery Department specialize in 
                addressing congenital anomalies, including those affecting the hand, foot, cleft lip, and cleft palate. 
                With advanced surgical techniques and compassionate care, we provide tailored treatment plans for each 
                patient, aiming to improve both function and aesthetics.
                For conditions such as cleft lip and cleft palate, our multidisciplinary team works closely 
                with patients and their families to ensure comprehensive care from infancy through adolescence. 
                Through surgical correction and ongoing support, we aim to optimize speech, feeding, and facial 
                appearance, fostering confidence and well-being.
                Similarly, for congenital anomalies affecting the hand and foot, our surgeons employ precise 
                reconstructive techniques to restore function and appearance. Our goal is to provide optimal 
                outcomes and enhance quality of life for our patients.`,
      },
      {
        name: `Diabetic Foot Management:`,
        details: `Our team is dedicated to providing comprehensive care for individuals with diabetes who 
                are at risk of foot complications. Led by experienced specialists, our aim is to focus on early 
                detection, prevention, and treatment of diabetic foot problems. Through regular foot exams, 
                patient education, and personalized treatment plans, we aim to minimize the risk of foot ulcers, 
                infections, and other complications associated with diabetes. Our team utilizes advanced wound care 
                techniques, offloading devices, and multidisciplinary approaches to optimize healing and prevent amputations.`,
      },
      {
        name: `Cosmetic Surgeries:`,
        details: `We have expertise in an array of cosmetic surgical procedures such as;`,
        list: [
          {
            content: `Breast augmentation and reduction surgeries Fat grafting procedures`,
          },
          { content: `Fat grafting procedures` },
          { content: `Rhinoplasty` },
          { content: `Gynaecomastia correction surgery` },
          { content: `Abdominoplasty` },
          { content: `Liposuction` },
          { content: `Body contouring` },
          { content: `Hair transplant` },
        ],
      },
      {
        name: `Burn Reconstruction Surgery:`,
        details: `Our Burn Reconstruction Surgery offers specialized care for individuals who have experienced burn 
                injuries. Led by expert plastic surgeons, our team is dedicated to restoring both function and aesthetics for 
                patients who have undergone burn trauma. Using advanced reconstructive techniques, including skin grafts, tissue 
                expansion, and microsurgery, we aim to improve the appearance and functionality of scarred areas while 
                promoting healing and restoring mobility. With compassionate care and personalized treatment plans, SP 
                Medifort is committed to helping patients regain confidence and improve their quality of life following burn 
                injuries.`,
      },
      {
        name: `Lymphoedema Management:`,
        details: `At SP Medifort, our Plastic Surgery Department offers specialized care for individuals with lymphedema, 
                a chronic condition characterized by swelling due to lymphatic system dysfunction. Led by skilled plastic surgeons,
                our lymphedema management program aims to improve lymphatic drainage and reduce swelling through 
                personalized treatment plans. With a focus on compassionate care and innovative solutions, SP Medifort 
                is dedicated to providing comprehensive lymphedema management services to improve the well-being of our patients.`,
      },
      {
        name: `Reconstructive Surgery`,
        details: `At SP Medifort's Plastic Surgery Department, we specialize in reconstructive procedures
                tailored to address various needs and conditions. Led by a team of skilled plastic
                surgeons, we offer a comprehensive range of surgeries aimed at restoring both
                appearance and function. From repairing trauma-related injuries to enhancing
                post-cancer outcomes, our focus is on providing personalized care and achieving
                natural-looking results. Using advanced techniques and a compassionate approach, SP
                Medifort is dedicated to helping patients regain confidence and improve their overall
                quality of life through reconstructive surgery.`,
      },
      {
        name: `Cleft Palate Surgery`,
        details: `Our cleft palate repair procedure offers comprehensive treatment options for patients of
                all ages. Led by a team of experienced plastic surgeons and specialists, we provide
                personalized care tailored to each individual's needs. Our approach encompasses a
                range of surgical techniques aimed at restoring proper function and aesthetics to the
                palate and surrounding structures. With a focus on precision and compassionate care,
                SP Medifort is committed to helping patients achieve optimal outcomes and improve
                their quality of life.`,
      },
      {
        name: `Post-Mastectomy Reconstruction`,
        details: `At SP Medifort, we offer advanced post-mastectomy reconstruction procedures to
                restore confidence and femininity for breast cancer survivors. Led by a team of skilled
                plastic surgeons and specialists, we provide personalized care tailored to each patient's
                unique needs and preferences. Our comprehensive approach includes a range of
                reconstructive options, such as implant-based reconstruction, autologous tissue flap
                reconstruction, and nipple reconstruction, among others. With a focus on patient
                well-being and aesthetic outcomes, SP Medifort is committed to helping breast cancer
                survivors regain a sense of wholeness and self-assurance following mastectomy.`,
      },
    ],
  },

  // Internal Medicine
  {
    department: "Internal Medicine",
    list: [
      {
        name: `Experienced physician:`,
        details: `We have the best physicians who will attend to all your medical needs`,
      },
      {
        name: `Preventive health checkups:`,
        details: `We offer regular preventive health check ups so that you’re always on top of your health!`,
      },
      {
        name: `Lab services:`,
        details: `We take great pride in our state-of-the-art laboratory services that ensure 
                all treatments and procedures are seamlessly carried out.`,
      },
      {
        name: `Evaluation of complex conditions:`,
        details: `We expertly evaluate conditions varied in complexities to find the best possible 
                treatment plan for each patient’s unique requirements.`,
      },
      {
        name: `Best IP care:`,
        details: `We have the best, state-of-the-art IP (in patient) care with advanced ICU facilities 
                under the able supervision of our most highly experienced and trained Internal Physicians.`,
      },
      {
        name: `Preventive Care`,
        details: `At SP Medifort, we prioritize preventive care to ensure our patients maintain optimal
health and well-being. Our comprehensive preventive care services encompass regular
health screenings, vaccinations, lifestyle counseling, and disease prevention strategies.
Led by a team of experienced healthcare professionals, we offer personalized preventive
care plans tailored to each individual's unique health needs and risk factors. Through
early detection and proactive management, SP Medifort empowers patients to take
control of their health and prevent the onset of illness and disease.`,
      },
      {
        name: `Lifestyle Disease management`,
        details: `At SP Medifort, we take a proactive approach to managing lifestyle diseases,
recognizing the importance of lifestyle modifications in promoting overall health and
well-being. Our specialized Lifestyle Disease Management program offers personalized
care and support to individuals affected by conditions such as diabetes, hypertension,
obesity, and cardiovascular disease. Led by a team of expert healthcare professionals,
we provide comprehensive assessments, personalized treatment plans, and ongoing
support to help patients make positive lifestyle changes. Through education, nutrition
counseling, exercise programs, and stress management techniques, we empower our
patients to take control of their health and reduce the risk of complications associated
with lifestyle-related conditions.`,
      },
    ],
  },

  // Cardiology
  {
    department: "Cardiology",
    list: [
      {
        name: `24X7 Chest Pain Clinic`,
        details: `At SP Medifort Centre for Cardiovascular Sciences, we understand the urgency of chest 
                pain and its potential implications for life. That's why we offer a dedicated 24x7 chest 
                pain clinic service, ensuring immediate access to expert care whenever you need it. Staffed 
                by experienced cardiologists and healthcare professionals, our chest pain clinic provides 
                rapid assessment, diagnosis, and treatment for individuals experiencing chest discomfort or 
                related symptoms. Whether it's a minor concern or a potential cardiac emergency, our team is 
                equipped with state-of-the-art facilities and advanced diagnostic tools to provide timely 
                interventions and peace of mind. At SP Medifort, your heart health is our priority, and our 
                round-the-clock chest pain clinic service is here to support you every step of the way.`,
      },
      {
        name: `Coronary Care Unit (CCU)`,
        details: `At SP Medifort, our state-of-the-art Coronary Care Unit (CCU) is designed to 
                provide the highest level of care for patients with cardiac conditions. Equipped with 
                cutting-edge technology and staffed by a multidisciplinary team of cardiologists, nurses, 
                and specialists, our CCU offers round-the-clock monitoring and interventions for individuals 
                with heart attacks, and other cardiac emergencies. With specialized cardiac monitoring equipment, 
                advanced imaging capabilities, and immediate access to life-saving treatments such as thrombolytic 
                therapy and percutaneous coronary intervention (PCI), our CCU ensures rapid and effective 
                management of critical cardiac events. At SP Medifort, we are dedicated to delivering 
                exceptional cardiac care in our state-of-the-art Coronary Care Unit, providing patients 
                with the best possible outcomes and peace of mind during their recovery journey.`,
      },
      {
        name: `Advanced 3D CATH Lab:`,
        details: `SP Medifort takes pride in our cutting-edge 3D Catheterization Laboratory, augmented by a 
                surgical backup facility, setting a new standard in cardiovascular care with intravascular imaging 
                and physiology. It also has IVL, rotablation, and other related facilities. This innovative lab 
                seamlessly integrates advanced imaging technology with surgical capabilities, allowing for precise 
                diagnosis and treatment of complex cardiac conditions and peripheral vascular diseases. With real-time 
                3D imaging, our expert cardiologists and cardiac surgeons collaborate to perform minimally invasive 
                interventions and surgical procedures, such as angiogram, angioplasty with stenting, valve interventions, 
                peripheral, arterial and venous interventions with unparalleled accuracy and efficiency. We also carry out 
                procedures like pacemaker and other device implantations and congenital heart disease interventions. In 
                the event of unexpected complications, our surgical backup facility ensures immediate access to comprehensive 
                cardiac surgery services, providing patients with the highest level of care and peace of mind.`,
      },
      {
        name: `Executive Cardiac Checkups`,
        details: `Our executive cardiac checkup program provides a holistic approach to cardiovascular wellness, combining 
                advanced diagnostic tests, specialized consultations with cardiologists, and personalized risk assessments. We also 
                carry out TMT procedures to monitor our patient’s heart health. Designed to fit seamlessly into busy schedules, these 
                checkups offer thorough evaluations of cardiac risk factors, with a focus on preventive care. Our expert team identifies 
                potential risk factors and provides tailored recommendations for lifestyle modifications and preventive measures 
                such as preventive cardiology. Through education on healthy eating, exercise habits, stress management techniques, 
                and smoking cessation strategies, we empower individuals to make informed choices and take control of their heart 
                health. At SP Medifort, we prioritize your heart health, offering executive cardiac checkups to empower you to proactively 
                manage your cardiovascular well-being and live a healthy, fulfilling life`,
      },
      {
        name: `Preventive Cardiology`,
        details: `Led by experienced cardiologists and healthcare professionals, our preventive
                cardiology sessions provide comprehensive assessments, personalized risk evaluations,
                and practical lifestyle recommendations. Through education on healthy eating, exercise
                habits, stress management techniques, and smoking cessation strategies, we empower
                individuals to make informed choices and take control of their heart health. Whether
                you're looking to prevent heart disease or manage existing risk factors, our preventive
                cardiology sessions at SP Medifort are designed to help you achieve optimal
                cardiovascular wellness.`,
      },
      {
        name: `Structured Cardiac Rehabilitation`,
        details: `At SP Medifort, we offer structured cardiac rehabilitation services designed to support
                patients in their journey to recovery and improved heart health. Led by a multidisciplinary
                team of cardiologists, nurses, physiotherapists, and nutritionists, our program provides
                personalized care and guidance tailored to each individual's needs. Through a structured
                regimen of exercise training, lifestyle modification, education, and counseling, we
                empower patients to regain strength, manage risk factors, and enhance overall
                cardiovascular wellness. Our goal is to optimize recovery, prevent future cardiac events,
                and improve quality of life. At SP Medifort, our structured cardiac rehabilitation service is
                dedicated to helping patients achieve lasting heart health and well-being.`,
      },
    ],
  },

  // Dermatology
  {
    department: "Dermatology",
    list: [
      {
        name: `Ultraviolet (UV) light therapy`,
        details: `At the dermatology clinic at SP Medifort, we offer advanced Ultraviolet (UV) light therapy
                as a safe and effective treatment option for various skin conditions. Led by experienced
                dermatologists, our UV light therapy sessions are tailored to address conditions such as
                psoriasis, eczema, vitiligo, and other inflammatory skin disorders. UV light therapy works
                by penetrating the skin to target abnormal immune responses and promote healing. With
                state-of-the-art equipment and personalized treatment plans, our clinic ensures optimal
                results while minimizing potential side effects. Whether as a standalone therapy or in
                combination with other treatments, UV light therapy at SP Medifort's dermatology clinic is
                designed to help patients achieve clearer, healthier skin.`,
      },
      {
        name: `Mole Removal`,
        details: `Our dermatology clinic offers safe and effective mole removal procedures in consultation with a plastic 
                surgeon whenever necessary, and is tailored to each patient's unique needs. Led by skilled dermatologists, our team 
                utilizes advanced techniques to remove moles that may be causing discomfort or are of concern for cosmetic reasons 
                or potential health risks. Whether through surgical excision, laser therapy, or other minimally invasive methods, 
                our mole removal procedures are performed with precision and care. Our goal is to ensure optimal cosmetic outcomes 
                while prioritizing patient comfort and safety. Trust SP Medifort for expert mole removal procedures, providing peace 
                of mind and enhancing confidence in your skin's appearance.`,
      },
      {
        name: `Chemical Peel Procedure`,
        details: `Our dermatology clinic offers chemical peel procedures as a safe and effective solution
                to rejuvenate and improve the appearance of the skin. Led by experienced
                dermatologists, our team provides personalized chemical peel treatments tailored to
                address various skin concerns, including acne, fine lines, wrinkles, uneven tone, and
                texture. Using medical-grade chemical solutions, the procedure removes damaged outer
                layers of skin, stimulating collagen production and promoting cell turnover for smoother,
                brighter, and more youthful-looking skin. With different peel depths and formulations
                available, our dermatologists customize treatments to suit each patient's skin type and
                desired outcome. Trust SP Medifort for expert chemical peel procedures, helping you
                achieve radiant, healthy skin and renewed confidence.`,
      },
      {
        name: `Vitiligo Surgery:`,
        details: `In cases of stable vitiligo not responding to medical management, we undertake 
                surgical procedures, mainly, punch grafting. This helps in repigmentation of non responding 
                vitiligo lesions and provides better cosmetic appearance of affected areas.`,
      },
      {
        name: `PRP Therapy:`,
        details: `Platelet Rich plasma Therapy is a popular treatment for facial rejuvenation and hair 
                growth. This is done for male and female pattern alopecia and alopecia from various other causes. 
                It is done for patients who require faster and a more aggressive correction of hair fall problems. 
                This procedure is done in collaboration with the transfusion medicine department.`,
      },
      {
        name: `Electrosurgery:`,
        details: `Various electrosurgical procedures like electrofulguration, electro coagulation, 
                and electro dessication are undertaken for treatment of most of the benign skin conditions 
                and is a good substitute for surgical excision.`,
      },
      {
        name: `Intralesional Therapy:`,
        details: `Intralesional injections of corticosteroids and other medications are monitored for various conditions especially like, keloids and alopecias`,
      },
    ],
  },

  // Pediatrics
  {
    department: "Pediatrics",
    list: [
      {
        name: `Specialized Immunization And Nutrition Clinic`,
        details: `We understand the importance of early childhood immunization and proper nutrition in
                ensuring the health and well-being of our young patients. That's why we offer a
                specialized Immunization and Nutrition Clinic for Pediatrics, providing comprehensive
                care and guidance for families. Led by pediatricians and nutrition specialists, our clinic
                offers personalized immunization schedules tailored to each child's age, health status,
                and individual needs. Additionally, our team provides expert nutritional counseling and
                support to promote healthy eating habits and optimal growth and development. With a
                focus on preventive care and education, SP Medifort's Immunization and Nutrition Clinic
                is dedicated to helping children thrive and reach their full potential.`,
      },
      {
        name: `Child Health Checks`,
        details: `At SP Medifort, we prioritize the health and well-being of children by offering
                comprehensive Child Health Checks services. Led by pediatricians and healthcare
                professionals specializing in pediatric care, our clinic provides thorough assessments
                and screenings tailored to each child's developmental stage and individual needs. From
                routine physical exams to vaccinations, growth monitoring, and developmental
                assessments, our goal is to ensure early detection of any health concerns and provide
                timely interventions as needed. With a focus on preventive care and parental education,
                SP Medifort's Child Health Checks services are designed to promote optimal growth,
                development, and overall health for children.`,
      },
      {
        name: `Pediatric Nutrition`,
        details: `We understand the critical role that nutrition plays in children's growth and development.
That's why we offer specialized Pediatric Nutrition Consultation services to ensure that
children receive the essential nutrients they need for optimal health. Led by experienced
pediatric nutritionists and dietitians, our consultations are tailored to each child's unique
dietary requirements, health goals, and medical conditions. Whether addressing
concerns about picky eating, food allergies, or managing chronic conditions such as
obesity or diabetes, our team provides expert guidance and practical strategies to
support healthy eating habits and overall wellness. With a focus on empowering families
to make informed food choices and establish lifelong healthy eating patterns, SP
Medifort's Pediatric Nutrition Consultation service is dedicated to helping children thrive.`,
      },
      {
        name: `Pediatric Asthma Care`,
        details: `At SP Medifort, we offer specialized pediatric asthma care to ensure children with asthma receive comprehensive support and treatment. 
                Led by a team of experienced respiratory therapists, our asthma care program provides personalized treatment plans tailored to each child's needs.
                 From thorough evaluations and diagnostic testing to ongoing management and education, our goal is to empower children and their families to 
                 effectively control asthma symptoms and improve quality of life. With a focus on preventive measures, symptom management, and patient education, SP 
                 Medifort's pediatric asthma care services are dedicated to helping children breathe easier and live healthier lives. We also have an Asthma Clinic 
                 where we offer therapies such as inhaler therapy.`,
      },
      {
        name: `ASTHMA & ALLERGY CLINIC`,
        details: `Asthma is a chronic (long-term) lung disease. It affects your airways, the tubes that carry air in and out of your lungs. When you have asthma, your airways can become inflamed and narrowed. This can cause wheezing, coughing, and tightness in your chest. When these symptoms get worse than usual, it is called an asthma attack or flare-up.
Asthma often starts during childhood, usually before age 5. It is one of the most common chronic diseases of childhood. This causes repeated hospitalization in children, leading to poor school performance and stress. Hence there is a need for asthma and allergy clinics.
An asthma attack can happen when your child is exposed to an asthma trigger. Allergic asthma is caused by allergens. They can include Dust mites, Mold, Pets, and Pollen. Nonallergic asthma is caused by triggers, such as, breathing in cold air, Household chemicals, outdoor air pollution and tobacco smoke.
The symptoms of asthma in children include chest tightness, coughing, especially at night or early morning, breathing problems, wheezing, and trouble eating.
It can be hard to diagnose asthma in children, especially if they are young. Asthma has similar symptoms as other childhood conditions. Our expert paediatrician may diagnose asthma with a Physical exam, Medical history, Chest x-ray, Lung function tests, Allergy skin or blood tests, peak expiratory flow rate (PEFR), skin prick test.
SP Medifort Asthma & Allergy Clinic focuses on evaluation and management of childhood asthma ensuring a healthy life for children.
`,
      },
      {
        name: `Pediatric Vaccinations`,
        details: `Our pediatric vaccination services offer a comprehensive range of immunizations to protect children from various vaccine-preventable diseases. Led by experienced pediatricians, our program provides personalized vaccination schedules tailored to each child's age, medical history, and individual needs. We offer a supportive and child-friendly environment to ensure a positive vaccination experience for both children and their families. With a commitment to promoting community health and preventing outbreaks, SP Medifort's pediatric vaccination services are dedicated to keeping children healthy and protected.
We also provide IP care for sick and ailing children
`,
      },
      {
        name: `Growth and Development Monitoring`,
        details: `We are dedicated to overseeing the progress of children's growth and development
through our specialized monitoring program. Our team, consisting of skilled pediatricians
and healthcare providers, conducts thorough assessments tailored to each child's
individual needs. We closely track various aspects of development, including physical
growth, cognitive abilities, language acquisition, and social-emotional milestones. By
closely monitoring these markers, we aim to detect any potential concerns early and
provide timely interventions. Our priority is to empower parents with valuable insights
and guidance to support their child's healthy development journey. Through our
proactive approach and personalized care, SP Medifort ensures that every child receives
the attention they need to thrive and reach their full potential.`,
      },
      {
        name: `Adolescent Counseling`,
        details: `We recognize the unique challenges adolescents face as they navigate through the
transition to adulthood. That's why we offer specialized adolescent counseling services
to provide support and guidance during this crucial stage of development. Led by
experienced counselors and mental health professionals, our program offers a safe and
confidential space for adolescents to explore their feelings, concerns, and aspirations.
We address a wide range of issues, including academic stress, peer relationships,
self-esteem, mental health, and coping skills. Our goal is to empower adolescents to
navigate life's challenges with confidence and resilience, promoting their emotional
well-being and personal growth. Through compassionate counseling and
evidence-based techniques, SP Medifort is committed to helping adolescents thrive and
achieve their full potential.`,
      },
    ],
  },

  // ENT
  {
    department: "ENT",
    list: [
      {
        name: `Tonsillectomy`,
        details: `Offering expert care and support for patients experiencing recurrent tonsillitis or other
                tonsil-related issues, our team is led by experienced otolaryngologists (ENT specialists),
                our team utilizes advanced surgical techniques to remove the tonsils safely and
                effectively. Tonsillectomy is often recommended for individuals who experience frequent
                sore throats, difficulty swallowing, or sleep-disordered breathing due to enlarged tonsils.
                Our goal is to provide relief from symptoms and improve overall quality of life. With a
                focus on patient comfort and safety, SP Medifort ensures optimal outcomes for every
                tonsillectomy procedure.`,
      },
      {
        name: `Nasal Endoscopy`,
        details: `We offer nasal endoscopy procedures as part of our comprehensive ENT services. Led
                by experienced otolaryngologists (ENT specialists), our team utilizes state-of-the-art
                endoscopic technology to diagnose and treat various nasal and sinus conditions. Nasal
                endoscopy involves inserting a thin, flexible tube with a camera and light attached
                (endoscope) into the nasal passages to visualize the nasal cavity, sinuses, and
                surrounding structures. This procedure allows for accurate diagnosis of conditions such
                as chronic sinusitis, nasal polyps, deviated septum, and tumors. With a focus on patient
                comfort and precise visualization, SP Medifort ensures thorough assessment and
                personalized treatment plans tailored to each patient's needs.`,
      },
      {
        name: `Nasal Bleeding`,
        details: `At SP Medifort, we offer cauterization procedures to effectively treat nasal bleeding
(epistaxis). Led by experienced otolaryngologists (ENT specialists), our team utilizes
cautery techniques to stop or reduce bleeding from the nasal blood vessels. During the
procedure, a specialized instrument is used to apply heat or chemicals to the bleeding
site, sealing off the blood vessels and promoting clot formation. Cauterization is
commonly recommended for recurrent or severe nosebleeds that do not respond to
conservative measures. Our goal is to provide prompt and effective relief from nasal
bleeding while minimizing discomfort and complications.`,
      },
      {
        name: `Microscopy of the ear/ Foreign body removal`,
        details: `Led by experienced otolaryngologists (ENT specialists), our team utilizes advanced
microscopy techniques to examine the ear canal and tympanic membrane with precision
and detail. This allows for accurate diagnosis of conditions such as ear infections,
perforations, and foreign body impactions. In cases where foreign bodies are present in
the ear canal, our specialists employ specialized tools and gentle techniques to safely
remove the object, ensuring minimal discomfort and risk of complications. With a focus
on patient safety and optimal outcomes, SP Medifort provides expert care and support
for microscopy of the ear and foreign body removal procedures.`,
      },
      {
        name: `Audiology and Speech Therapy`,
        details: `At SP Medifort, we offer comprehensive audiology and speech therapy services to
address a wide range of hearing and communication disorders. Led by experienced
audiologists and speech-language pathologists, our team provides personalized
assessments and therapies tailored to each patient's unique needs. Our audiology
services include hearing evaluations, diagnostic testing, and fitting of hearing aids and
assistive devices to improve auditory function. Additionally, our speech therapy services
focus on evaluating and treating speech, language, voice, and swallowing disorders,
helping patients improve communication skills and quality of life. Whether you're
experiencing hearing loss, speech difficulties, or other communication challenges, SP
Medifort's audiology and speech therapy team is dedicated to providing expert care and
support to help you achieve your communication goals.`,
      },
      {
        name: `Speech Therapy`,
        details: `At SP Medifort, we offer specialized speech assessment and therapy services to
address a wide range of speech and language disorders. Led by experienced
speech-language pathologists, our team provides comprehensive evaluations to assess
speech articulation, language comprehension and expression, fluency, voice quality, and
swallowing function. Based on the assessment findings, personalized therapy plans are
developed to target specific areas of need and help individuals improve their
communication skills. Our therapy sessions utilize evidence-based techniques and
interventions to address speech sound production, language development, social
communication, and swallowing difficulties. Whether you're seeking support for
articulation disorders, language delays, stuttering, voice disorders, or swallowing
problems, SP Medifort's speech assessment and therapy services are tailored to meet
your unique needs and empower you to communicate effectively.`,
      },
      {
        name: `Post-cochlear implantation therapy`,
        details: `At SP Medifort, our post-cochlear implantation therapy supports individuals who have
undergone cochlear implant surgery. Led by experienced professionals, including audiologists
and speech-language pathologists, we provide tailored therapy to optimize communication
outcomes and adapt to the new auditory experience. Our goal is to enhance speech and
language skills, improve sound interpretation, and address emotional aspects of cochlear
implantation. Trust us to provide expert care and support throughout your cochlear implant
journey.`,
      },
      {
        name: `Laryngology`,
        details: `Our laryngology services focus on the diagnosis and treatment of disorders affecting the
larynx (voice box) and vocal cords. Led by experienced otolaryngologists (ENT
specialists) with expertise in laryngology, our team provides comprehensive evaluations
and personalized treatment plans for conditions such as voice disorders, laryngitis, vocal
cord nodules, polyps, and paralysis. We offer state-of-the-art diagnostic techniques,
including laryngoscopy and stroboscopy, to accurately assess vocal cord function and
structure. Our goal is to restore vocal health and function, improve voice quality, and
optimize communication abilities for our patients.`,
      },
      {
        name: `Para Nasal Sinuses`,
        details: `Our expertise extends to the diagnosis and treatment of para-nasal sinus conditions,
including sinusitis and nasal polyps. Led by skilled otolaryngologists (ENT specialists),
our team utilizes advanced imaging techniques and endoscopic procedures to
accurately assess and manage para-nasal sinus disorders. We offer personalized
treatment plans tailored to each patient's specific needs, which may include medication
management, nasal irrigation, sinus surgery, or minimally invasive procedures. Our goal
is to alleviate symptoms, improve sinus function, and enhance overall quality of life for
our patients. Trust SP Medifort for expert care and support in addressing para-nasal
sinus conditions, ensuring optimal outcomes and relief from sinus-related issues.`,
      },
      {
        name: `Surgery Of Throat And Neck`,
        details: `Led by experienced otolaryngologists (ENT specialists), our team provides
comprehensive evaluations and personalized treatment plans for disorders such as
tonsillitis, thyroid nodules, vocal cord lesions, and neck masses. We utilize advanced
surgical techniques, including minimally invasive procedures and robotic-assisted
surgery, to ensure optimal outcomes and faster recovery times for our patients. Whether
you require tonsillectomy, thyroidectomy, or surgery for head and neck cancer, trust SP
Medifort to deliver expert care and support throughout your treatment journey, restoring
function and enhancing quality of life.`,
      },
    ],
  },

  // Nurology
  {
    department: "Neurology",
    list: [
      {
        name: `Epilepsy surgery`,
        details: `We offer comprehensive care for patients with refractory epilepsy. Led by experienced
neurosurgeons and epileptologists, our multidisciplinary team conducts thorough
evaluations to determine the most appropriate surgical approach for each individual. We
utilize advanced neuroimaging techniques, such as MRI and PET scans, along with
invasive monitoring procedures, to precisely localize seizure foci and identify candidates
for surgery. Surgical options may include resective surgery, such as temporal lobectomy
or lesionectomy, as well as neuromodulation techniques like vagus nerve stimulation
(VNS) or deep brain stimulation (DBS). Our goal is to provide tailored treatment plans
that reduce seizure frequency and improve quality of life for patients with epilepsy.`,
      },
      {
        name: `Vascular Surgery`,
        details: `Our vascular surgery department specializes in the diagnosis and treatment of
conditions affecting the blood vessels throughout the body. Led by experienced vascular
surgeons, our team offers comprehensive care for arterial and venous disorders,
including peripheral artery disease, aortic aneurysms, varicose veins, and deep vein
thrombosis. We utilize advanced imaging techniques and minimally invasive procedures,
such as angioplasty, stenting, and endovascular repair, to restore blood flow and
improve circulation. Our goal is to provide personalized treatment plans that address
each patient's unique vascular needs, reducing symptoms and minimizing complications.`,
      },
      {
        name: `Craniotomy`,
        details: `At SP Medifort, our neurosurgery department specializes in craniotomy procedures,
which involve surgical access to the brain through the skull. Led by experienced
neurosurgeons, our team utilizes advanced techniques and state-of-the-art technology to
perform craniotomies for a wide range of neurological conditions, including brain tumors,
aneurysms, vascular malformations, and traumatic brain injuries. We prioritize patient
safety and comfort throughout the procedure, employing precise surgical planning and
intraoperative monitoring to ensure optimal outcomes. Our goal is to provide
personalized treatment plans that address each patient's specific needs, while
maximizing the potential for recovery and improving overall quality of life.`,
      },
      {
        name: `EMG`,
        details: `Our neurology department offers electromyography (EMG) services to diagnose and
evaluate conditions affecting the muscles and nerves. Led by experienced neurologists
and electromyography, our team utilizes advanced EMG techniques to assess muscle
function, nerve conduction, and electrical activity within the body. EMG testing may be
used to diagnose conditions such as peripheral neuropathy, muscular dystrophy,
myasthenia gravis, and carpal tunnel syndrome. Our goal is to provide accurate and
timely diagnosis, guiding treatment decisions and optimizing patient care.`,
      },
    ],
  },

  // General surgery
  {
    department: "General Surgery",
    list: [
      {
        name: `Head and neck Surgery`,
        details: `Our Head and Neck Surgery services stand as a beacon of excellence, committed to providing compassionate care and cutting-edge treatments. Led by a team of skilled surgeons who are pioneers in their field, we specialize in diagnosing and treating a wide range of conditions affecting the head and neck region, from benign tumors to complex reconstructive procedures. With state-of-the-art technology and a patient-centric approach, we strive to achieve the best possible outcomes for our patients, ensuring their comfort and well-being every step of the way.`,
      },
      {
        name: `Vascular surgery`,
        details: `At SP Medifort, our Vascular Surgery department is dedicated to delivering exceptional care for conditions affecting the circulatory system. Led by a team of highly skilled vascular surgeons, we specialize in diagnosing and treating a wide range of vascular disorders, from peripheral artery disease to aneurysms. With a focus on innovative techniques and personalized treatment plans, we prioritize patient safety and satisfaction. Whether it's minimally invasive procedures or complex vascular reconstructions, we are committed to providing the highest quality of care to improve our patients' quality of life.`,
      },
      {
        name: `Endocrine surgery`,
        details: `Our Endocrine Surgery surgeons are dedicated to the diagnosis and treatment of disorders affecting the endocrine system. Led by a team of experienced surgeons specializing in endocrine conditions, we offer comprehensive care for patients with thyroid, parathyroid, adrenal, and other endocrine gland disorders. Using advanced surgical techniques, including minimally invasive approaches, we prioritize precise interventions with minimal discomfort and faster recovery times. Our patient-centered approach ensures that each individual receives personalized care tailored to their specific needs and preferences.`,
      },
      {
        name: `Trauma surgery`,
        details: `Trauma Surgery at SP Medifort is at the forefront of emergency care, providing swift and expert treatment to patients with critical trauma injuries. 
                Led by a multidisciplinary team of trauma surgeons, we excel in managing a wide spectrum of traumatic injuries, from blunt force trauma to penetrating wounds. Our 
                trauma center is equipped with state-of-the-art facilities, including advanced imaging technology and operating rooms, ensuring rapid assessment and intervention 
                for the best possible outcomes. We take great pride in having state-of-the-art trauma care units, advanced 24x7 trauma care intensive care unit facilities. With a 
                focus on collaboration and efficiency, we work seamlessly with other specialists, including neurosurgeons, orthopedic surgeons, and critical care physicians, to 
                deliver comprehensive care tailored to each patient's unique needs.`,
      },
      {
        name: `Gastrointestinal Surgeries`,
        details: `At SP Medifort, gastrointestinal surgeries encompass a spectrum of innovative procedures aimed at addressing a range of digestive system disorders. 
                Our expert surgical team, equipped with state-of-the-art technology, performs procedures such as laparoscopic surgery, bowel resection, hernia repair, 
                and bariatric surgery with precision and care. Whether treating conditions like appendicitis, diverticulitis, or gastrointestinal cancers, our approach 
                prioritizes patient well-being, emphasizing minimally invasive techniques whenever possible to reduce recovery times and optimize outcomes. With a focus 
                on comprehensive pre-operative evaluations, personalized treatment plans, and attentive post-operative care, SP Medifort ensures that patients receive the 
                highest standard of gastrointestinal surgical care, enabling them to reclaim their health and vitality.`,
      },
    ],
  },

  // robotic & Laparoscopic
  {
    department: "Robotic & Laparoscopic General Surgery",
    list: [
      {
        name: `Robotic Surgery`,
        details: `At SP Medifort, robotic surgery has revolutionized the way we approach complex procedures. Our state-of-the-art robotic surgical system 
                allows for unparalleled precision, flexibility, and control, enabling our expert surgeons to perform minimally invasive surgeries with enhanced 
                accuracy and efficiency. Patients benefit from smaller incisions, reduced pain, faster recovery times, and improved outcomes. With a dedicated team 
                of highly skilled surgeons and cutting-edge technology, SP Medifort is committed to delivering the highest standard of care through robotic surgery, 
                ensuring our patients receive the best possible treatment options available.`,
      },
      {
        name: `Minimally Invasive Surgery For Varicose Veins`,
        details: `We offer advanced minimally invasive surgery for varicose veins, providing patients with a highly effective and less invasive alternative 
                to traditional surgical procedures. Our experienced vascular surgeons target varicose veins precisely, resulting in minimal scarring, reduced pain, 
                and quicker recovery times. Our approach focuses on patient comfort and safety, ensuring excellent outcomes and improved quality of life. 
                With a commitment to innovation and patient-centered care, SP Medifort remains at the forefront of delivering cutting-edge treatments for 
                varicose veins, helping patients regain confidence and mobility with minimal disruption to their daily lives.`,
      },
      {
        name: `Obesity Clinic`,
        details: `Bariatric surgery
                At SP Medifort, bariatric surgery represents a life-changing option for individuals struggling with obesity. Our comprehensive bariatric surgery program 
                is tailored to each patient's unique needs, offering a range of minimally invasive procedures designed to promote significant weight loss and improve 
                overall health. With a focus on safety, efficacy, and long-term success, our multidisciplinary team of surgeons, dietitians, psychologists, and support 
                staff provides personalized care every step of the way. From pre-operative evaluations to post-operative follow-up, we prioritize patient education, 
                counseling, and ongoing support to ensure optimal outcomes and sustained lifestyle changes.`,
      },
      {
        name: `Endoscopic Keyhole Surgeries for Thyroid Disorders`,
        details: `SP Medifort leads the way in providing cutting-edge endoscopic keyhole surgeries for thyroid disorders. Our expert team of surgeons utilizes 
                state-of-the-art endoscopic techniques to perform minimally invasive procedures, offering patients precise treatment with minimal scarring and 
                faster recovery times. By accessing the thyroid gland through small incisions, we ensure a reduced risk of complications and a more comfortable 
                post-operative experience. Whether treating thyroid nodules, goiters, or thyroid cancer, our approach prioritizes patient well-being and optimal outcomes. 
                At SP Medifort, we're committed to delivering advanced care that combines innovation with compassion, empowering patients to overcome thyroid disorders and 
                regain their quality of life swiftly and confidently.`,
      },
      {
        name: `Minimally Invasive Hernia Surgeries`,
        details: `We specialize in minimally invasive hernia surgeries, offering patients a safer and more efficient alternative to traditional open procedures. 
                Our experienced team of surgeons utilizes advanced laparoscopic techniques to repair hernias with smaller incisions, resulting in reduced post-operative 
                pain, shorter hospital stays, and faster recovery times. By employing state-of-the-art instruments and imaging technology, we ensure 
                precise and effective hernia repair while minimizing tissue trauma. Patients can trust SP Medifort for expert care that prioritizes their comfort and well-being, 
                enabling them to return to their daily activities with minimal disruption.`,
      },
      {
        name: `Robotic Surgery For GERD Diseases`,
        details: `SP Medifort pioneers robotic surgery for GERD (Gastroesophageal Reflux Disease), offering patients a groundbreaking approach to treatment. Utilizing state-of-the-art robotic 
                surgical systems, our skilled team of surgeons can perform highly precise procedures with unparalleled dexterity and control. Robotic surgery for GERD provides patients 
                with minimally invasive options, resulting in smaller incisions, reduced pain, and quicker recovery times compared to traditional surgery. By 
                targeting the underlying causes of GERD, such as hiatal hernias or weak lower esophageal sphincters, we aim to alleviate symptoms and improve quality of life for our patients.`,
      },
      {
        name: `Hernia Repair`,
        details: `Hernia repair at SP Medifort is conducted with precision and care, offering effective solutions for patients experiencing discomfort and complications due to hernias. Led by skilled surgeons with expertise in hernia repair techniques, including both open and minimally invasive approaches, we prioritize patient safety and satisfaction. Our team conducts thorough evaluations to determine the most appropriate treatment plan tailored to each individual's unique needs. Whether it's inguinal, umbilical, or hiatal hernia repair, we strive for optimal outcomes and a quick recovery. With state-of-the-art facilities and a patient-centered approach, SP Medifort ensures that every hernia repair procedure is performed with the utmost professionalism and compassion, restoring comfort and well-being to our patients.`,
      },
      {
        name: `Hysterectomy`,
        details: `Hysterectomy at SP Medifort offers a solution for women facing various gynecological conditions, including uterine fibroids, endometriosis, and uterine prolapse. Led by a team of compassionate and skilled gynecologic surgeons, we provide personalized care and support throughout the entire process. Whether performed traditionally or using minimally invasive techniques such as laparoscopic or robotic surgery, our goal is to ensure the best possible outcomes while minimizing discomfort and recovery time. Before the procedure, our team conducts thorough evaluations and discusses treatment options to tailor the approach to each patient's specific needs and preferences. Postoperatively, we provide comprehensive follow-up care to ensure a smooth recovery and address any concerns.`,
      },
      {
        name: `Appendectomy`,
        details: `Appendectomy is a swift and effective procedure for individuals experiencing appendicitis, a condition characterized by inflammation of the appendix. Led by experienced surgeons specializing in abdominal surgery, we prioritize prompt diagnosis and treatment to prevent complications and promote a speedy recovery. Whether performed using traditional open surgery or minimally invasive techniques such as laparoscopy, our approach focuses on removing the inflamed appendix while minimizing discomfort and postoperative complications. Our dedicated team ensures thorough preoperative evaluations and personalized care plans tailored to each patient's needs. Postoperatively, we provide comprehensive support to facilitate a smooth recovery and monitor for any signs of complications.`,
      },
      {
        name: `Colorectal Laparoscopic Surgery`,
        details: `At SP Medifort, our colorectal laparoscopic surgery offers minimally invasive solutions for various colorectal conditions. Led by experienced surgeons, we utilize advanced techniques, resulting in less pain, shorter hospital stays, and quicker recovery times. From colorectal cancer to diverticulitis, our tailored approach ensures effective treatment with optimal outcomes. Postoperatively, our multidisciplinary team provides comprehensive support to ensure a smooth recovery and long-term success`,
      },
    ],
  },

  // urology
  {
    department: "Urology",
    list: [
      {
        name: `Laser Treatments:`,
        details: `We offer a wide range of specialized laser treatments such as:`,
        list: [
          { content: `Laser surgeries for bladder tumors` },
          { content: `Laser surgeries for prostate problems` },
          {
            content: `Laser Lithotripsy, which is a way to treat kidney stones. This treatment uses a laser to break 
                              kidney stones into tiny pieces, making it easier for extraction from the body.`,
          },
        ],
      },
      {
        name: `Kidney stones`,
        details: `Kidney stones can cause significant discomfort and complications, but at SP Medifort, we offer comprehensive care to address this issue effectively. Led by experienced urologists, our approach to kidney stones involves accurate diagnosis, personalized treatment plans, and compassionate support throughout your journey to recovery. Whether through minimally invasive procedures like lithotripsy or surgical intervention when necessary, we prioritize your comfort and well-being. With state-of-the-art facilities and a patient-centered approach, SP Medifort ensures that you receive the highest quality care to alleviate kidney stone symptoms and prevent recurrence.`,
      },
      {
        name: `Urologic Oncology`,
        details: `Urologic Oncology at SP Medifort stands as a beacon of expertise and compassion in the field of urologic cancer care. Led by a distinguished team of urologic oncologists, we are dedicated to providing comprehensive and personalized care to patients facing cancers of the urinary tract and male reproductive system. Our approach begins with meticulous diagnosis, utilizing advanced imaging techniques and biomarker analysis to accurately identify the type, stage, and extent of the cancer. With this information in hand, we collaborate closely with each patient to develop a tailored treatment plan that may include surgery, radiation therapy, chemotherapy, immunotherapy, or a combination of these modalities. At SP Medifort, we leverage the latest advancements in urologic oncology, including minimally invasive surgical techniques and targeted therapies, to achieve the best possible outcomes while minimizing side effects and preserving quality of life.`,
      },
      {
        name: `Prostate cancer`,
        details: `Prostate Cancer care at SP Medifort encompasses a range of cutting-edge approaches aimed at ensuring optimal outcomes for patients. From initial diagnosis using advanced imaging techniques and biomarker analysis to the development of personalized treatment plans, we leave no stone unturned in addressing this condition. Our options span active surveillance for low-risk cases to surgical interventions, such as radical prostatectomy, and innovative treatments like targeted radiation therapy or hormone therapy for more aggressive cancers. Throughout this journey, we emphasize patient education, support, and involvement in decision-making to empower individuals to make informed choices about their care. With a focus on minimizing side effects and preserving quality of life, our multidisciplinary team stands ready to provide the expertise and compassion needed to navigate the complexities of prostate cancer treatment.`,
      },
      {
        name: `Urinary tract infection`,
        details: `We offer effective diagnosis and treatment to alleviate symptoms and promote recovery. Led by experienced urologists and infectious disease specialists, our approach to UTIs involves thorough evaluation, including urine analysis and culture, to identify the specific bacteria causing the infection. Based on the results, we prescribe appropriate antibiotics and provide supportive care to relieve symptoms and prevent complications. In addition to treatment, we emphasize the importance of prevention strategies, such as staying hydrated, practicing good hygiene, and avoiding irritants like perfumed products. Our team is dedicated to providing compassionate care and support to help patients manage and prevent UTIs, ensuring their comfort and well-being.`,
      },
      {
        name: `Ureteroscopy`,
        details: `Ureteroscopy at SP Medifort is a minimally invasive procedure used to diagnose and treat conditions affecting the urinary tract, such as kidney stones or tumors. Led by skilled urologists specializing in endourology, our team utilizes a thin, flexible instrument called a ureteroscope, which is inserted through the urethra and bladder to access the ureters and kidneys. We prioritize patient safety and comfort, providing thorough preoperative evaluations and personalized care plans tailored to each individual's needs. Post-operatively, our team offers comprehensive support to ensure a smooth recovery and optimal outcomes.`,
      },
    ],
  },

  // Oncology
  {
    department: "Oncology",
    list: [
      {
        name: `Chemotherapy`,
        details: `At SP Medifort, chemotherapy is more than just treatment—it's a cornerstone of our compassionate cancer care approach. Led by expert oncologists, our personalized chemotherapy regimens target cancer cells while prioritizing patient comfort and well-being. From managing side effects to offering emotional support, we're dedicated to guiding you through every step of your journey with empathy and expertise. Trust us for comprehensive chemotherapy and compassionate cancer care that puts you first.`,
      },
      {
        name: `Immunotherapy`,
        details: `At SP Medifort, immunotherapy stands as a beacon of hope in the fight against cancer and other immune-related diseases. Our cutting-edge 
                immunotherapy program harnesses the power of the body's own immune system to target and destroy cancer cells with remarkable precision. Through 
                personalized treatment plans tailored to each patient's specific condition and genetic profile, we aim to minimize side effects while maximizing 
                therapeutic efficacy. At SP Medifort, we are committed to offering hope and healing through the transformative potential of immunotherapy, empowering 
                our patients to live longer, healthier lives.`,
      },
      {
        name: `Targeted therapy`,
        details: `Targeted therapy at SP Medifort offers precise treatment for various medical conditions, particularly cancer. Led by specialized medical oncologists, this approach involves using drugs or other substances to specifically target and attack cancer cells, while minimizing harm to normal cells. With personalized treatment plans and cutting-edge techniques, our aim is to provide effective therapy with fewer side effects. We provide expert targeted therapy that's tailored to your unique needs and condition.`,
      },
      {
        name: `Cancer surgery`,
        details: `Cancer surgery at SP Medifort is a critical component of our comprehensive cancer care approach. Led by skilled surgical oncologists, our team specializes in various cancer surgeries tailored to each patient's specific diagnosis and needs. Whether it's removing tumors, lymph nodes, or affected tissues, our goal is to provide precise and effective treatment while preserving organ function and minimizing side effects. With state-of-the-art technology and a patient-centered approach, we strive to achieve the best possible outcomes for our patients. Trust SP Medifort for expert cancer surgery that prioritizes your health and well-being.`,
      },
    ],
  },

  // Emergency medicine
  {
    department: "Emergency Medicine",
    list: [
      {
        name: `Emergency Medicine`,
        details: `Emergency and trauma care refers to the specialized medical care provided to 
                individuals who experience sudden, life-threatening injuries or illnesses. It encompasses 
                a wide range of services designed to stabilize and treat patients in critical conditions. 
                We have the best rapid response trauma team consisting of neurosurgeons, orthopedic 
                surgeons, general surgeons, plastic surgeons, etc. In emergency and trauma care, the 
                primary goal is to rapidly assess and stabilize patients to prevent further deterioration 
                and save lives. This can involve providing initial first aid, administering medications, 
                performing resuscitation techniques, controlling bleeding, managing pain, and ensuring the 
                patient’s vital signs are stable. Emergency and trauma care is typically delivered in 
                specialized hospital departments called emergency departments or trauma centers. These facilities 
                are equipped with advanced medical technology, diagnostic tools, and a skilled healthcare team 
                trained to handle critical situations. They often have various specialists available, 
                including emergency physicians, trauma surgeons, anesthesiologists, nurses, and other 
                healthcare professionals who work together to provide immediate and comprehensive care. The 
                types of conditions treated in emergency and trauma care can include heart attacks, strokes, 
                severe allergic reactions, respiratory distress, and pediatric medical and surgical emergencies. 
                Prompt and appropriate care in these situations can greatly improve the chances of survival and 
                recovery for patients.`,
      },
      {
        name: `Heart Attacks`,
        details: `At SP Medifort's Emergency Department, heart attacks are swiftly diagnosed and treated. 
                Our team employs rapid assessment techniques, including ECG and cardiac enzyme tests, to confirm 
                the diagnosis. Immediate interventions, such as medication administration and emergency procedures like 
                PCI, are implemented to restore blood flow to the heart. With expert care and close monitoring, we 
                prioritize patient safety and aim for optimal outcomes in heart attack emergencies.`,
      },
      {
        name: `Acute Strokes`,
        details: `acute strokes are promptly identified and treated. Patients receive rapid assessment, 
                including imaging studies, to determine the type and severity of the stroke. Immediate interventions, 
                such as clot-busting medications or mechanical thrombectomy, are implemented to restore blood flow to 
                the brain. Our multidisciplinary team works collaboratively to provide comprehensive care and support, 
                prioritizing patient safety and optimal outcomes in acute stroke emergencies.`,
      },
      {
        name: `Poisoning`,
        details: `In the SP Medifort Emergency Department, patients with poisoning receive immediate 
                evaluation and treatment. Our team quickly assesses symptoms and toxin exposure, administering 
                antidotes and supportive care as needed. Patient safety and recovery are our top priorities, 
                ensuring prompt and effective interventions for poisoning emergencies.`,
      },
      {
        name: `Anaphylaxis`,
        details: `Treatment for anaphylaxis at our emergency department is swift and decisive. 
                Upon arrival, patients receive immediate assessment, including evaluation of symptoms and 
                potential triggers. Our team administers epinephrine to counteract allergic reactions and 
                may also provide oxygen therapy and intravenous fluids to stabilize the patient's condition. 
                Close monitoring of vital signs and respiratory function is maintained to ensure patient safety.
                Additional medications, such as antihistamines and corticosteroids, may be administered to alleviate 
                symptoms and prevent recurrence. Our dedicated healthcare professionals prioritize patient well-being, 
                providing comprehensive care and support throughout the treatment process.`,
      },
    ],
  },

  // Critical Care
  {
    department: "Critical Care",
    list: [
      {
        name: `Palliative care`,
        details: `At SP Medifort's Critical Care Unit, we prioritize compassionate and supportive palliative care for patients facing life-limiting illnesses. Led by a team of skilled healthcare professionals, including palliative care doctors and nurses, we focus on alleviating pain and discomfort, managing symptoms, and addressing the emotional and spiritual needs of patients and their families. We provide personalized care plans that emphasize dignity, respect, and quality of life, ensuring that patients receive comfort and support throughout their journey.`,
      },
      {
        name: `Advanced Ventilators`,
        details: `We have extremely advanced ventilators equipped with indirect calorimetry, bronchoscopy, tracheostomy, arterial line and POCUS.`,
      },
      {
        name: `CRRT`,
        details: `Continuous renal replacement therapy is a special type of dialysis that we do for unstable patients in the ICU whose bodies cannot tolerate regular dialysis. Instead of doing it over four hours, CRRT is done 24 hours a day to slowly and continuously clean out the waste products and fluid from the patient.`,
      },
      {
        name: `ECMO`,
        details: `ECMO is used for people who are critically ill and are unable to respond. Whose lungs are not responding to provide enough oxygen to the parts even after giving extra oxygen. Their lungs are unable to carry out carbon dioxide even with the help of a mechanical ventilator.`,
      },
      {
        name: `Parenteral nutrition`,
        details: `Parenteral nutrition means feeding intravenously (through a vein). "Parenteral" means "outside of the digestive tract." Whereas enteral nutrition is delivered through a tube to your stomach or the small intestine, parenteral nutrition bypasses your entire digestive system, from mouth to anus. This process is especially carried out in critically ill patients.`,
      },

      {
        name: `Cardiogenic Shock`,
        details: `In SP Medifort's Critical Care Unit, management of cardiogenic shock is immediate and comprehensive. Patients receive rapid assessment and stabilization by our skilled critical care team, which includes cardiologists, intensivists, nurses, and respiratory therapists. Treatment involves a multifaceted approach, including administering medications to improve heart function and blood flow, such as vasopressors and inotropes. Close monitoring of vital signs, cardiac function, and fluid status is maintained to guide treatment adjustments and ensure optimal response. Our team also addresses underlying causes, such as myocardial infarction or arrhythmias, through targeted interventions and therapies. Throughout the critical care journey, our priority is to provide expert, compassionate care tailored to each patient's specific needs.`,
      },
      {
        name: `Cerebral Edema`,
        details: `In the Critical Care Unit at SP Medifort, managing cerebral edema involves prompt and specialized care by our experienced critical care team. Upon admission, patients with cerebral edema receive immediate assessment and monitoring to evaluate neurological status, intracranial pressure (ICP), and vital signs. Treatment strategies aim to reduce brain swelling and prevent further damage. Continuous monitoring of ICP, brain perfusion, and neurological function guides treatment adjustments and ensures patient safety. Our team also addresses underlying causes of cerebral edema, such as traumatic brain injury or stroke, through targeted interventions and therapies. Throughout the critical care journey, our priority is to provide expert, compassionate care.`,
      },
      {
        name: `Acute Respiratory Failure`,
        details: `In the Critical Care Unit at SP Medifort, acute respiratory failure receives immediate and specialized attention from our skilled critical care team. Patients undergo prompt assessment and intervention to stabilize their respiratory function and optimize oxygen levels. Treatment may include oxygen therapy, non-invasive ventilation, or mechanical ventilation, tailored to each patient's needs. Close monitoring and targeted interventions aim to address underlying causes and ensure optimal respiratory support. Our priority is to provide expert, compassionate care, working collaboratively to achieve the best possible outcomes for every patient with acute respiratory failure.`,
      },
      {
        name: `Acute Liver Failure`,
        details: `Acute liver failure receives urgent and specialized care from our experienced team. Patients undergo immediate assessment and intervention to stabilize liver function and manage complications. Treatment may involve supportive measures such as intravenous fluids, medications to manage symptoms, and interventions to address underlying causes. Close monitoring of liver function and vital signs guides treatment adjustments to optimize outcomes.`,
      },
      {
        name: `Stroke`,
        details: `In SP Medifort's Critical Care Unit, stroke patients receive immediate and expert care from our specialized team. Rapid assessment, including imaging studies, guides treatment decisions aimed at restoring blood flow to the brain. Our multidisciplinary approach ensures comprehensive support and monitoring to optimize outcomes. With a focus on prompt intervention and personalized care, we strive to achieve the best possible outcomes for every stroke patient in critical care.`,
      },
      {
        name: `Other services include`,
        details: ``,
        list: [
          {
            content: "Capnometry",
          },
          {
            content: "Transcranial doppler",
          },
          {
            content: "Cardiac output monitors",
          },
        ],
      },
    ],
  },

  // Pediatric Surgery
  {
    department: "Pediatric Surgery",
    list: [
      {
        name: `Birth defect`,
        details: `In pediatric surgery at SP Medifort, children with birth defects receive comprehensive care from our specialized team. 
                Our pediatric surgeons are skilled in diagnosing and treating a wide range of congenital abnormalities, like cleft lip and palate. 
                From the initial evaluation to surgical intervention and follow-up care, our team provides compassionate support to both children 
                and their families. We tailor treatment plans to each child's unique needs, aiming to optimize function and quality of life. 
                With state-of-the-art facilities and a multidisciplinary approach involving pediatricians, nurses, and therapists, we are 
                committed to providing the highest standard of care for children with birth defects.
`,
      },
      {
        name: `General Paediatric Surgery`,
        details: `Includes common conditions like Inguinal Hernia in infants and children, Umbilical Hernia, Undescended testis etc. This also includes common 
                emergencies like Acute Appendicitis, Acute Intussusception, Acute scrotum, Burns/ Scalds, Foreign body ingestion etc.`,
      },
      {
        name: `Paediatric Abdominal surgery`,
        details: `Includes conditions like Biliary atresia, Choledochal cyst, Ovarian cyst etc.`,
      },
      {
        name: `Paediatric Genitourinary surgery`,
        details: `Includes common conditions like Hydronephrosis, PUJO, VUR, PUV etc and Hypospadias. It 
                includes other common conditions like Neurogenic Bladder, Voiding disorders and Antenatally detected Hydronephrosis.`,
      },
      {
        name: `Paediatric Plastic/ Reconstructive Surgery`,
        details: `Includes Cleft lip and Cleft palate, surgery for CTEV and Syndactyly`,
      },
      {
        name: `Special Clinics proposed`,
        details: ``,
        list: [
          {
            heading: `1. Hypospadias & DSD clinic`,
            content: `Hypospadias is the second most common congenital defect in children. The treatment of Disorders of sexual differentiation (DSD) requires a 
                        multidisciplinary team which can provide a high standard of care. We are proud to have the first dedicated & exclusive Hypospadias treatment unit in South India.`,
          },
          {
            heading: `2. Antenatally detected fetal anomaly clinic`,
            content: `Mothers with USS detected fetal anomalies (major/ minor) can get expert opinion from a multidisciplinary team & therapy, where required, under a single unit.`,
          },
          {
            heading: `3. Voiding disorders & Neurogenic Bladder clinic`,
            content: `Children with common, but inadequately treated conditions like Dysfunctional Voiding & Neurogenic Bladder secondary to Hydrocephalus and Spina bifida have to 
                        be ideally treated by a multidisciplinary team.`,
          },
        ],
      },
      {
        name: `Some other Specialities Include:`,
        details: ``,
        list: [
          {
            content: `Exclusive & dedicated clinic for pregnant mothers with sonologically detected fetal anomalies.`,
          },
          {
            content: `Multidisciplinary team including the Paediatric Surgeon, Paediatrician & Neonatologist, Sonologist, Nephrologist, Neurosurgeon, Cardiologist and other 
                        specialists where required for precise diagnosis, prognostication and therapy.`,
          },
          {
            content: `Scientific and clinically oriented analysis of the problems based on the current best practice, detailed grading systems and thorough evidence-based approach.`,
          },
          {
            content: `Use of advanced imaging techniques and co-ordinated sharing of the data between the concerned specialists, to avoid the need for the pregnant mother to make 
                        multiple consultations & hospital visits.`,
          },
          {
            content: `Proper guidance, mental support, reassurance and thorough prenatal and postnatal follow up to ensure the most ideal outcome.`,
          },
          {
            content: `The “First of its kind", under a single roof, facility for all Antenatally detected fetal anomalies.`,
          },
        ],
      },
      {
        name: `Cleft Lip and Cleft Palate`,
        details: `Children born with Cleft lip and Cleft Palate require detailed evaluation and therapy from early in life. The importance of timely surgery 
                at expert hands cannot be over emphasized, because of the bearing on future life and long term outcome. A specialized clinic and unit with a multidisciplinary 
                approach can give the ideal therapy and support to these kids and their distressed parents. The exclusive Cleft lip & Cleft Palate clinic at SP Medifort 
                works on an interdisciplinary collaborative approach involving the Paediatric Surgeon, Paediatrician, Plastic Surgeon, Maxillofacial surgeon, Paediatric 
                Anaesthetist, Speech Therapist &. Clinical Psychologist. This facility provides the ideal environment to the child for safe & effective surgery and thorough 
                follow up. The vastly experienced team, the state-of-the- art support system and the exclusive nursing care constitute the cornerstone 
                for a successful outcome.`,
      },
      {
        name: `Day Care Surgery Clinic`,
        details: `We also provide the service of a day care surgery clinic for common office procedures like surgery for inguinal hernia, umbilical hernia, 
                undescended testes and circumcision for Phimosis.`,
      },
    ],
  },

  // Physiotherappy
  {
    department: "Physiotherapy",
    list: [
      {
        name: `Soft Tissue Mobilization`,
        details: `Soft tissue mobilization is a key technique used to address musculoskeletal issues and promote healing. Our skilled physiotherapists employ hands-on techniques to manipulate and massage soft tissues, such as muscles, tendons, and ligaments, to alleviate pain, improve flexibility, and restore function. Through targeted mobilization techniques, our team aims to break down scar tissue, release muscle tension, and improve circulation to the affected area. This promotes tissue healing, reduces inflammation, and enhances overall mobility and range of motion. With personalized treatment plans tailored to each patient's specific needs and goals, we prioritize their comfort and well-being throughout the rehabilitation process.`,
      },
      {
        name: `Strength Training`,
        details: `Strength training plays a crucial role in rehabilitation and recovery. Our skilled physiotherapists design tailored strength training programs to address individual needs and goals, whether it's recovering from injury, managing chronic conditions, or enhancing athletic performance. Through a combination of resistance exercises, functional movements, and specialized equipment, our strength training programs target specific muscle groups to improve strength, endurance, and overall physical function. With a focus on proper technique, safety, and progression, our physiotherapists guide patients through their strength training regimen, providing education and support every step of the way.`,
      },
      {
        name: `Comprehensive Rehabilitation`,
        details: `At SP Medifort, our physiotherapy team offers comprehensive physical rehabilitation programs. Tailored to individual needs, these programs integrate various techniques like manual therapy, exercises, and specialized equipment to enhance mobility, strength, and function. With personalized care and a multidisciplinary approach, we empower patients to achieve optimal recovery and resume their daily activities with confidence.`,
      },
      {
        name: `Pain Management`,
        details: `Pain management is a primary focus of our care. Our experienced physiotherapists utilize a variety of techniques to address pain and discomfort, helping patients regain function and improve their quality of life. With a patient-centered approach, we work closely with each individual to develop personalized treatment plans that target their specific pain sources and address their unique needs and goals. At SP Medifort, our goal is to empower patients to effectively manage their pain and achieve long-term relief through expert physiotherapy care.`,
      },
      {
        name: `Postoperative Rehabilitation`,
        details: `Post-operative rehabilitation in physiotherapy at SP Medifort is tailored to each patient's unique needs and surgical procedure. Our skilled physiotherapists design personalized rehabilitation programs to promote healing, restore function, and minimize complications following surgery. Through a combination of manual therapy, therapeutic exercises, we aim to reduce pain, swelling, and stiffness, while improving range of motion and strength. We also provide education on proper body mechanics and postural awareness to enhance recovery and prevent re-injury. With a focus on gradual progression and patient-centered care, our goal is to optimize outcomes and facilitate a safe return to daily activities.`,
      },
      {
        name: `We also provide an array of treatments like`,
        details: ``,
        list: [
          { content: "Onco Rehabilitation" },
          { content: "Pelvic Health" },
          { content: "Sports Rehabilitation" },
          { content: "Hand Therapy" },
          { content: "Neuro Rehabilitation" },
          { content: "Musculoskeletal Rehabilitation" },
        ],
        team: [
          { name: "Anjana S" },
          { name: "Jithinjith DR" },
          { name: "Asif Mohammed" },
          { name: "Aiswarya SR" },
        ],
      },
    ],
  },

  // Endocrinology
  {
    department: "Endocrinology",
    list: [
      {
        name: `Diabetes Mellitus`,
        details: `At SP Medifort's Endocrinology Department, we offer comprehensive care for patients with Diabetes Mellitus, a chronic condition affecting blood sugar regulation. Led by expert endocrinologists and diabetes specialists, our department provides personalized treatment plans tailored to each patient's unique needs. Through a combination of medication management, lifestyle modifications, and patient education, we aim to optimize blood sugar control, prevent complications, and improve overall quality of life for individuals with diabetes`,
      },
      {
        name: `Thyroid disorders: Hypothyroidism and Hyperthyroidism`,
        details: `At SP Medifort's Endocrinology Department, we specialize in the treatment of thyroid disorders, offering comprehensive care for conditions such as hypothyroidism, hyperthyroidism, thyroid nodules, and thyroid cancer. Led by experienced endocrinologists, our team provides personalized treatment plans tailored to each patient's specific thyroid condition and health needs. Through advanced diagnostic testing, medication management, and, when necessary, surgical interventions, we aim to optimize thyroid function, alleviate symptoms, and improve overall well-being. With a patient-centered approach and a commitment to excellence, SP Medifort's Endocrinology Department is dedicated to delivering compassionate care and empowering patients to achieve optimal thyroid health.`,
      },
      {
        name: `Obesity and Fatty Liver`,
        details: `At SP Medifort, we recognize the complex interplay between obesity and conditions like fatty liver disease. In our dedicated endocrinology department, we provide comprehensive treatment strategies that address both aspects. Led by experienced specialists, our approach combines lifestyle modifications, dietary counseling, physical activity plans, and, when necessary, medical interventions to manage obesity effectively. We also offer tailored management strategies for fatty liver disease, including medications, nutritional support, and monitoring for complications. With a multidisciplinary team and personalized care plans, SP Medifort is committed to helping patients achieve healthier weights and better liver health, improving their overall well-being.`,
      },
      {
        name: `Menstrual disorder, PCOS and Infertility:`,
        details: `At SP Medifort, our endocrine department offers specialized care for menstrual disorders, polycystic ovary syndrome (PCOS), and infertility. Led by experienced endocrinologists, our team provides comprehensive diagnostic evaluations and personalized treatment plans tailored to each patient's unique needs. Whether it's irregular periods, hormonal imbalances, or difficulty conceiving, we offer a range of treatment options, including medications, lifestyle modifications, fertility treatments, and assisted reproductive technologies. With a compassionate and supportive approach, SP Medifort is dedicated to helping women overcome menstrual disorders, PCOS, and infertility challenges, empowering them to achieve their reproductive goals.`,
      },
      {
        name: `Bone Health and Calcium Disorder Evaluations`,
        details: `Our endocrinology department carries out Bone Health and Calcium Disorder Evaluations dedicated to assessing and managing conditions related to bone health. Led by specialists, we provide comprehensive diagnostic evaluations to identify bone disorders. Through advanced imaging techniques, laboratory tests, and clinical assessments, we tailor treatment plans to each patient's specific needs, focusing on optimizing bone density and preventing fractures. With a holistic approach that includes lifestyle modifications, nutritional counseling, and, when necessary, medication therapy, SP Medifort is committed to promoting bone health and enhancing quality of life for our patients.`,
      },
      {
        name: `Adrenal Gland Disorders`,
        details: `Our Endocrinology Department specializes in the diagnosis and treatment of adrenal gland disorders. Led by experienced endocrinologists, our team provides comprehensive care for conditions such as adrenal insufficiency, Cushing's syndrome and adrenal tumors. Through advanced diagnostic testing, including hormone level assessments and imaging studies, we accurately diagnose adrenal gland disorders and develop personalized treatment plans tailored to each patient's needs. Treatment may include medication management, hormonal replacement therapy, or surgical intervention, depending on the underlying condition.`,
      },
      {
        name: `Resistant Hypertension`,
        details: `We provide specialized care for patients with resistant hypertension, a challenging condition that requires comprehensive evaluation and management. Our team conducts thorough diagnostic assessments to identify underlying causes and contributing factors to hypertension. We then develop personalized treatment plans tailored to each patient's unique needs, which may include lifestyle modifications, medication adjustments, and innovative therapies. We also expertly treat conditions like Hyperaldosteronism and pheochromocytoma.`,
      },
      {
        name: `Pituitary Gland Disorders`,
        details: `We treat Pituitary Gland Disorders and other related disorders such as Prolactin disorders, acromegaly, and hypopituitarism.`,
      },
      {
        name: `Pediatric Endocrine Disorders`,
        details: `We treat a number of conditions related to pediatric endocrine disorders such as:`,
        list: [
          { content: "Short stature" },
          { content: "Pubertal disorders i.e early or delayed puberty" },
          { content: "Care of syndromic child" },
          { content: "Rickets and other childhood bone disorders" },
          { content: "Disorders of 6 differentiation" },
          { content: "Congenital adrenal hyperplasia" },
          {
            content:
              "Childhood thyroid disorders, including congenital hypothyroidism",
          },
        ],
      },
    ],
  },

  // Anesthesia
  {
    department: "Anesthesia",
    list: [
      {
        name: `Pain Clinic for Chronic Pain`,
        details: `SP Medifort's Pain Clinic within the Anesthesia Department is a beacon of hope for individuals 
                suffering from chronic pain. Our dedicated team of pain management specialists combines expertise with compassion 
                to provide personalized care tailored to each patient's needs. Through a comprehensive approach that may include 
                medication management, interventional procedures, physical therapy, and psychological support, we aim to alleviate 
                pain and improve overall quality of life. With a focus on holistic healing and patient empowerment, our Pain Clinic offers a 
                supportive environment where individuals can find relief and regain control over their lives.`,
      },
      {
        name: `Expert Intensive Care Physicians`,
        details: `SP Medifort's Intensive Care Physicians are the cornerstone of our commitment to excellence in critical care. 
                With extensive training and experience, our expert physicians are dedicated to providing the highest level of care to patients 
                in the most critical moments of their lives. Whether managing complex medical conditions, overseeing post-operative 
                recovery, or responding to emergencies, our team utilizes advanced medical technologies and evidence-based practices 
                to optimize outcomes. Beyond their clinical expertise, our physicians demonstrate compassion and empathy, offering support to patients and 
                their families during challenging times.`,
      },
    ],
  },

  // Radiology
  {
    department: "Radiology",
    list: [
      {
        name: `Diagnostic and Interventional Radiology`,
        details: `Radiology services are housed in a 5000 square feet space at SP Medifort Hospital. It is one of the few 
                hospitals that possess all the latest technological advancement when it comes to infrastructure. The equipment here 
                is state of art technology. Across the spectrum of imaging, the facility is digital with PACS integration enabling 
                transmission and archival of images and reports. The imaging equipment comprises 1.5 Tesla MRI, 128 Slice AI integrated MDCT, 
                High-end Ultrasound scanners, Mammography, Digital fluoroscopy and X-ray units housed in the Department. We extend the 
                patient care in imaging by portable Ultrasound, X-ray and C-arm Units. We deliver conventional radiographic, sonographic 
                and cross-sectional imaging requirements. Our facility provides services not only to diagnose diseases but also offers 
                treatment to a variety of conditions using minimally invasive techniques without any scar.`,
      },
      {
        name: `Modalities and Procedures`,
        details: ``,
        list: [
          {
            heading: `1. Ultrasound Imaging`,
            content: `High-end Ultrasound and Colour Doppler`,
          },
          { content: "Abdominopelvic imaging" },
          { content: "Antenatal imaging" },
          { content: "Thyroid & Breast imaging" },
          { content: "Pediatric imaging including neonatal hip sonography" },
          { content: "Neurosonography" },
          { content: "High resolution Musculoskeletal sonography" },
          {
            heading: `2. CT Imaging`,
            content: `Multiphase Contrast studies with 128 slice Al integrated MDCT`,
          },
          { content: "Brain and body CT Angiography and Venography" },
          {
            content:
              "Cardiac CT - Calcium scoring, Coronary Angiography, TAVI protocol",
          },
          { content: "CT Enterography" },
          { content: "CT Virtual Bronchoscopy " },
          { content: "CT Virtual Colonoscopy" },
        ],
      },
      {
        name: `MR Imaging`,
        details: ``,
        list: [
          {
            content:
              "Characterizing Hepato-biliary and Pancreatic Lesions, splenic, adrenal, peritoneal, retroperitoneal and GI masses",
          },
          { content: "MR Cholangiopancreatography (MRCP)" },
          { content: "Cardiac MRI" },
          { content: "MR Angiography, Enterography" },
          {
            content:
              "MRI Brain Spectroscopy for characterisation of brain lesion",
          },
          {
            content:
              "Body MRI- for characterisation of lesions in chest, Abdomen & Pelvis",
          },
          { content: "Musculoskeletal MRI-Imaging of joints and muscles" },
        ],
      },
      {
        name: `Interventional Radiology`,
        details: ``,
        list: [
          { content: "Biopsy, Drainage, Aspiration" },
          { content: "Biopsies of masses and other body SOL" },
          { content: "Core biopsy for diffuse liver diseases" },
        ],
      },
      {
        name: `CT Guided Procedure`,
        details: ``,
        list: [
          { content: "Biopsy, aspiration, drainages" },
          { content: "Biopsy of hepatic, pancreatic and other massesL" },
          {
            content:
              "Aspiration/Pigtail catheter drainage of Pancreatic and other fluid collection",
          },
          { content: "Placement of Percutaneous Drainage Catheters (PCD)" },
          { content: "Cholecystostomy" },
        ],
      },
    ],
  },

  // Nephrology   
  {
    department: "Nephrology",
    list: [
      {
        name: `Nephrology`,
        details: `At SP Medifort, we are committed to delivering exceptional nephrology care with a team of renowned nephrologists, dialysis experts, and healthcare professionals. Specializing in the diagnosis, treatment, and management of kidney-related conditions, we offer a comprehensive range of services tailored to individual patient needs. Our team is skilled in handling everything from early-stage kidney disease to advanced renal failure, providing holistic and multidisciplinary care for a wide spectrum of kidney disorders. Our focus spans key areas, including chronic kidney disease (CKD), hypertension, electrolyte imbalances, glomerulonephritis, kidney stones, and diabetic nephropathy. Whether through medical management, lifestyle interventions, or dialysis, we strive to improve kidney health and overall well-being. At SP Medifort, we combine expertise, innovation, and compassionate care to ensure optimal outcomes for every patient.`,
      },
      {
        name: `Hemodialysis and Peritoneal Dialysis`,
        details: `Our Nephrology Department at SP Medifort offers state-of-the-art dialysis services, including both hemodialysis and peritoneal dialysis, for patients with chronic kidney failure. With a dedicated dialysis unit that operates under stringent safety standards, we provide high-quality care designed for patient comfort. Our highly trained staff ensures regular monitoring of patients during the treatment process to maintain electrolyte balance and improve kidney function. Our personalized dialysis treatment plans are developed based on the unique needs of each patient, optimizing their health outcomes and quality of life. Whether as a long-term treatment for end-stage renal disease or as a bridge to kidney transplantation, our dialysis services are crafted to offer comfort, dignity, and effective results.`,
      },
      {
        name: `Kidney Transplant Program`,
        details: `SP Medifort's Kidney Transplant Program is at the forefront of nephrology care, offering life-saving kidney transplants for patients with end-stage renal disease. With a multidisciplinary team of experienced transplant surgeons, nephrologists, and specialized nurses, we ensure comprehensive care throughout the transplant process—from pre-transplant evaluations and donor matching to post-operative management and long-term care. Our advanced surgical techniques and robust immunosuppressive therapies significantly enhance transplant success rates and patient outcomes. We prioritize patient education, ensuring that individuals and their families are well-informed about the transplant journey and post-surgery care. At SP Medifort, our kidney transplant program stands as a beacon of hope, restoring lives with advanced, compassionate care.`,
      },
      {
        name: `Hypertension Management`,
        details: `Managing high blood pressure is critical to kidney health, and at SP Medifort, we offer specialized hypertension management services. Our nephrologists are experts in diagnosing and treating complex cases of hypertension, especially those linked to kidney disorders. Through a combination of lifestyle modifications, advanced medications, and ongoing monitoring, we work closely with patients to control blood pressure and protect kidney function. By focusing on prevention and early intervention, our hypertension management services help reduce the risk of kidney damage and improve overall health.`,
      },
      {
        name: `Comprehensive Kidney Care`,
        details: `SP Medifort’s Nephrology Department provides comprehensive care for various kidney diseases, including acute kidney injury, glomerulonephritis, polycystic kidney disease, and nephrotic syndrome. Our advanced diagnostic capabilities, including blood tests, urine tests, imaging, and kidney biopsies, allow for accurate diagnosis and targeted treatment plans. Whether through medication, dialysis, or lifestyle changes, our holistic approach ensures that each patient receives tailored care aimed at preserving kidney function and enhancing quality of life.
At SP Medifort, the Department of Nephrology is dedicated to providing innovative treatments, empathetic care, and world-class services for all kidney-related conditions. From routine check-ups to complex surgical interventions, we are here to support you every step of the way.`,
      },
    ],
  },
  //  Laryngology 
  {
    department: "Laryngology",
    list: [
      {
        name: `Laryngology`,
        details: `At SP Medifort, the Department of Laryngology is dedicated to providing expert care for disorders of the throat, voice box, and upper airway. Our team of highly trained laryngologists, speech therapists, and healthcare professionals offers comprehensive diagnostic and therapeutic services for patients of all ages. Whether you are dealing with voice disorders, airway obstructions, or chronic throat conditions, we are committed to delivering personalized care that enhances your quality of life. We specialize in the management of conditions such as vocal cord paralysis, laryngeal cancer, chronic hoarseness, throat infections, and swallowing disorders (dysphagia). Our focus is on delivering exceptional results by combining advanced medical expertise with compassionate care tailored to each patient’s needs.`,
      },
      {
        name: `Voice and Swallowing Disorders`,
        details: `The Department of Laryngology at SP Medifort specializes in the diagnosis and treatment of voice and swallowing disorders. Our team addresses conditions like vocal cord polyps, nodules, vocal cord paralysis, and dysphonia. We employ cutting-edge diagnostic techniques such as videostroboscopy, laryngeal electromyography (EMG), and voice analysis to assess voice function accurately. For swallowing disorders (dysphagia), we provide a range of interventions including speech therapy, swallowing therapy, and advanced surgical procedures. Our specialists work closely with speech therapists to ensure a holistic approach to restoring normal voice and swallowing function.`,
      },
      {
        name: `Minimally Invasive Laryngeal Surgery`,
        details: `SP Medifort offers state-of-the-art minimally invasive surgical techniques to treat a variety of throat and laryngeal conditions. Our laryngologists are skilled in performing procedures such as laser surgery, microlaryngoscopy, and endoscopic vocal cord surgery. These procedures are designed to treat vocal cord lesions, airway obstructions, and laryngeal cancers with minimal discomfort and faster recovery times. Our team prioritizes preserving and restoring voice quality while addressing underlying health conditions, making these treatments highly effective and patient-friendly.`,
      },
      {
        name: `Airway Management and Tracheal Stenosis`,
        details: `Our Laryngology Department provides expert care for patients suffering from airway disorders such as tracheal stenosis, subglottic stenosis, and vocal cord paralysis. Using advanced diagnostic tools like bronchoscopy and CT imaging, we develop personalized treatment plans that may include airway dilation, tracheal reconstruction, or stenting procedures. Our approach ensures improved breathing function and enhanced quality of life for patients with complex airway conditions. For patients requiring long-term airway management, we offer comprehensive follow-up care and rehabilitation services.`,
      },
      {
        name: `Head and Neck Cancer Surgery`,
        details: `SP Medifort's Laryngology Department is at the forefront of head and neck cancer care, providing advanced surgical treatments for cancers of the larynx, throat, and upper airway. Our multidisciplinary team includes oncologists, radiologists, and rehabilitation specialists who collaborate to offer a complete spectrum of care. Our expertise includes minimally invasive surgeries, organ preservation techniques, and robotic-assisted surgeries that ensure precision and improved outcomes. Post-operative care focuses on rehabilitation, including speech and swallowing therapy, ensuring that patients maintain a high quality of life after treatment.`,
      },
      {
        name: `Laryngopharyngeal Reflux (LPR) Treatment`,
        details: `Laryngopharyngeal reflux (LPR) can cause chronic throat irritation, hoarseness, and swallowing difficulties. At SP Medifort, we offer specialized diagnostic tools such as pH monitoring and laryngoscopy to accurately diagnose and treat LPR. Our team develops personalized management plans, including dietary modifications, medications, and lifestyle changes, to reduce symptoms and improve throat health. For severe cases, we offer advanced surgical treatments, ensuring long-term relief from this condition.`,
      },
      {
        name: `Pediatric Laryngology`,
        details: `At SP Medifort, we provide specialized care for children with voice and airway disorders. Our pediatric laryngologists are experienced in diagnosing and treating conditions such as laryngomalacia, vocal cord paralysis, airway obstructions, and congenital laryngeal anomalies. We offer minimally invasive surgeries and non-surgical interventions to treat these conditions, ensuring a compassionate approach tailored to the needs of our younger patients.`,
      },
      {
        name: `Snoring and Sleep Apnea Treatment`,
        details: `Our Laryngology Department offers advanced treatment for snoring and obstructive sleep apnea (OSA). We provide a thorough evaluation, including sleep studies and endoscopic airway assessments, to identify the cause of sleep-related breathing disorders. Treatment options range from lifestyle changes and Continuous Positive Airway Pressure (CPAP) therapy to minimally invasive surgical procedures, such as uvulopalatopharyngoplasty (UPPP) and tongue base reduction, aimed at relieving airway obstruction and improving sleep quality.`,
      },
      {
        name: `Comprehensive Laryngology Care`,
        details: `The Department of Laryngology at SP Medifort is dedicated to providing a full range of diagnostic, medical, and surgical treatments for throat, voice, and airway conditions. From routine check-ups to complex surgeries, our team is committed to offering individualized care that enhances patient outcomes. We focus on patient education, preventive care, and long-term follow-up to ensure optimal results. Our holistic approach combines medical innovation with compassionate care, helping patients regain their voice, restore their breathing, and improve their overall quality of life.
At SP Medifort, we believe in delivering world-class laryngology care through expertise, innovation, and empathy. Whether you are seeking treatment for a common throat condition or a complex airway disorder, we are here to support you every step of the way.
`,
      },
    ],
  },
  // Respiratory Medicine  
  {
    department: "Respiratory Medicine",
    list: [
      {
        name: `Respiratory Medicine`,
        details: `At SP Medifort, our Department of Respiratory Medicine is dedicated to providing top-tier care for patients with a wide range of respiratory conditions. Our team of renowned pulmonologists, respiratory therapists, and healthcare professionals specializes in the diagnosis, treatment, and management of both acute and chronic lung diseases. We offer personalized, evidence-based care designed to enhance respiratory function and overall well-being. Our department addresses conditions such as asthma, chronic obstructive pulmonary disease (COPD), interstitial lung disease (ILD), pneumonia, lung infections, and sleep-related breathing disorders. Whether through medical management, pulmonary rehabilitation, or advanced procedures, we are committed to helping our patients breathe easier and live better. At SP Medifort, we combine expertise, innovation, and compassionate care to deliver exceptional outcomes in respiratory health.`,
      },
      {
        name: `Pulmonary Function Tests (PFTs)`,
        details: `SP Medifort offers advanced pulmonary function testing to assess lung capacity, airflow, and gas exchange. These non-invasive tests help in the diagnosis and monitoring of respiratory conditions like asthma, COPD, and interstitial lung disease. Our experienced technicians and pulmonologists use state-of-the-art equipment to provide accurate results, which guide the development of tailored treatment plans for each patient. PFTs are crucial for both initial diagnosis and ongoing management of lung diseases, ensuring optimal care and treatment adjustment as needed.`,
      },
      {
        name: `Bronchoscopy`,
        details: `The Respiratory Medicine Department at SP Medifort is equipped with cutting-edge technology for bronchoscopy, a minimally invasive procedure used to diagnose and treat conditions affecting the airways and lungs. Bronchoscopy allows our specialists to examine the airways, collect tissue samples, and perform interventions such as removing blockages or treating infections. This procedure is vital for diagnosing complex lung conditions, including lung cancer, infections, and chronic inflammatory diseases. Our highly skilled pulmonologists ensure patient safety and comfort throughout the procedure, delivering precise and effective care.`,
      },
      {
        name: `Asthma and Allergy Management`,
        details: `Managing asthma and allergies is a key focus at SP Medifort’s Respiratory Medicine Department. Our specialists offer comprehensive care for patients with allergic respiratory disorders and asthma, creating personalized treatment plans that include medications, inhalers, and lifestyle changes. We also provide allergy testing to identify specific triggers, allowing for targeted management strategies. Through patient education and regular monitoring, we aim to minimize asthma attacks and improve quality of life for those with chronic respiratory allergies.`,
      },
      {
        name: `Head and Neck Cancer Surgery`,
        details: `SP Medifort's Laryngology Department is at the forefront of head and neck cancer care, providing advanced surgical treatments for cancers of the larynx, throat, and upper airway. Our multidisciplinary team includes oncologists, radiologists, and rehabilitation specialists who collaborate to offer a complete spectrum of care. Our expertise includes minimally invasive surgeries, organ preservation techniques, and robotic-assisted surgeries that ensure precision and improved outcomes. Post-operative care focuses on rehabilitation, including speech and swallowing therapy, ensuring that patients maintain a high quality of life after treatment.`,
      },
      {
        name: `COPD Management`,
        details: `Chronic Obstructive Pulmonary Disease (COPD) requires specialized, long-term care. At SP Medifort, our pulmonologists develop individualized treatment plans to manage COPD symptoms, slow disease progression, and improve respiratory function. Using a combination of medications, pulmonary rehabilitation, oxygen therapy, and lifestyle modifications, we provide comprehensive care that enhances our patients' ability to breathe and engage in daily activities. Our COPD management programs are designed to reduce the frequency of exacerbations and hospitalizations, improving overall quality of life.`,
      },
      {
        name: `Sleep Apnea and Sleep Disorders`,
        details: `Our Sleep Medicine experts specialize in the diagnosis and treatment of sleep-related breathing disorders, including obstructive sleep apnea (OSA). Using advanced diagnostic tools like polysomnography (sleep studies), we assess and treat conditions that disrupt healthy sleep patterns. Continuous Positive Airway Pressure (CPAP) therapy, lifestyle modifications, and other treatment options are offered to help patients achieve restful sleep and prevent complications such as hypertension and cardiovascular issues. Our goal is to improve both sleep quality and overall health.`,
      },
      {
        name: `Pulmonary Rehabilitation`,
        details: `For patients with chronic respiratory conditions, SP Medifort offers a comprehensive pulmonary rehabilitation program. This multidisciplinary approach includes physical therapy, breathing exercises, nutritional guidance, and psychological support to improve lung function and overall physical health. Our rehabilitation program is designed to help patients manage symptoms, reduce breathlessness, and improve stamina, enabling them to lead more active, fulfilling lives.`,
      },
      {
        name: `Interstitial Lung Disease (ILD) Management`,
        details: `Our specialists at SP Medifort provide expert care for patients with Interstitial Lung Disease (ILD), offering a range of diagnostic and treatment options tailored to this complex group of disorders. Through advanced imaging, lung biopsies, and pulmonary function tests, we diagnose and manage ILD with precision. Our comprehensive care plans may include medications, oxygen therapy, and lifestyle changes to slow disease progression and improve quality of life. We are dedicated to providing holistic and compassionate care for patients with ILD and other rare lung diseases.`,
      },
      {
        name: `Lung Cancer Screening and Treatment`,
        details: `Early detection of lung cancer is crucial for successful treatment. At SP Medifort, we offer lung cancer screening programs using low-dose CT scans, enabling early diagnosis for high-risk individuals. Our multidisciplinary team collaborates to provide personalized treatment plans that may include surgery, chemotherapy, radiation, or targeted therapies. With cutting-edge technology and a focus on patient-centered care, we strive to achieve the best possible outcomes for our patients.`,
      },
      {
        name: `Comprehensive Respiratory Care`,
        details: `SP Medifort’s Department of Respiratory Medicine provides comprehensive care for a wide range of lung diseases, including pneumonia, bronchitis, tuberculosis, and other respiratory infections. With advanced diagnostic tools, our team can quickly identify and treat infections, ensuring faster recovery and reducing the risk of complications. Our holistic approach includes patient education, preventive care, and long-term management to maintain respiratory health and prevent future illnesses.
At SP Medifort, the Department of Respiratory Medicine is dedicated to providing innovative treatments, empathetic care, and world-class services for all lung-related conditions. From routine check-ups to advanced surgical interventions, we are here to support you in every breath you take.`,
      },
    ],
  },
];
